import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import ArrivalCertKeys from 'src/constants/locale/key/ArrivalCert'
import {
  constants,
} from 'cng-web-lib'
import moment from 'moment'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {

  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let maxLength25 = translate(
    Namespace.ARRIVAL_CERT,
    ArrivalCertKeys.Validations.MAX_LENGTH_25
  )
  let minLength5 = translate(
    Namespace.ARRIVAL_CERT,
    ArrivalCertKeys.Validations.MIN_LENGTH_5
  )
  let alphaNumericRegx = translate(
    Namespace.ARRIVAL_CERT,
    ArrivalCertKeys.Validations.ALPHA_NUMERIC_REGX
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )
  let arrivalDateTimeFormat = translate(
    Namespace.ARRIVAL_CERT,
    ArrivalCertKeys.Validations.INVALID_ARRIVAL_DATE_TIME_FORMAT
  )

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  let regexDateTime = "[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):[0-5][0-9]"
  const regexAlphaNumeric =
  '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'
  const errMsgAlphaNumeric =
  'Business Number should not contains special Characters like <,>,/,*,~.'

  return Yup.object({
    ccn: Yup.string().when('id', {
      is: (value) => value,
      then: Yup.string().required(requiredMessage).trim().max(25, maxLength25).min(5, minLength5).matches(/^[a-zA-Z0-9-]+$/, alphaNumericRegx),
    }).nullable(),
    ccnList: Yup.array().when('id', {
      is: (value) => !value,
      then: Yup.array(Yup.object({
        ccn: Yup.string().required(requiredMessage).trim().max(25, maxLength25).min(5, minLength5).matches(/^[a-zA-Z0-9-]+$/, alphaNumericRegx),
      })).nullable(),
    }).nullable(),
    arrivalDateTime: Yup.string().required(requiredMessage).matches(regexDateTime, arrivalDateTimeFormat).nullable(),
    arrivalTimeZone: Yup.string().nullable(),
    destinationOfficeCode: Yup.string().required(requiredMessage).nullable(),
    warehouseCode: Yup.string().nullable(),
    messageFunction: Yup.string().nullable(),
    businessNumber: Yup.string().matches(regexAlphaNumeric, errMsgAlphaNumeric).max(16, "Max Length allowed is 16").nullable(),
    status: Yup.string(),
    remarks: Yup.string(),
  })
}

export default makeValidationSchema
