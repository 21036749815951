import React, { useEffect, useState } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import ArrivalCertKeys from 'src/constants/locale/key/ArrivalCert'
import { useFormContext, useWatch } from "react-hook-form";
import { useLocation } from 'react-router-dom'
import { Card, CardContent, Grid, Divider } from '@material-ui/core'
import {
  components,
  DataFlattener,
  DateTimeFormatter,
} from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import { NaCustomsOfficeAutocompleteField } from 'src/components/na'
import { NaSublocationAutocompleteField, NaSublocationWithoutCodeAutocompleteField } from 'src/components/na'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import CollapsibleSection from 'src/components/ui/CollapsibleSection';
import CngSection from 'src/components/cngcomponents/CngSection';
import CCNEntry from './CCNEntry'
import CCNFormProperties from './CCNFormProperties'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    adapter: {
      useFormAdapter: { useField }
    },
    field: {
      CngTextField,
      CngDateField,
      CngDateTimeField,
      CngCodeMasterAutocompleteField,
      CngSwitchField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: "",
  ccn: "",
  arrivalDateTime: "",
  arrivalTimeZone: "",
  destinationOfficeCode: "",
  warehouseCode: "",
  messageFunction: "",
  businessNumber: "",
  status: "",
  remarks: "",
  modeOfSubmission: "",
  partyId: "",
  submittedBy: 0,
  submittedDate: "",
  createdBy: 0,
  createdDate: "",
  updatedBy: 0,
  updatedDate: "",
  version: "",
  businessNumFlag: false,
  oriCreatedBy: "",
  oriUpdatedBy: "",
  oriSubmittedBy: "",
  apiAutoSubmit: null,
  ccnList: [],
  errorDesc: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const location = useLocation();
  const { setValue, watch, getValues } = useFormContext();
  const { translate } = useTranslation(Namespace.ARRIVAL_CERT)
  const translatedTextsObject = makeTranslatedTextsObject()
  const businessNumFlag = watch('businessNumFlag')
  const ccnList = watch('ccnList')
  const { _ccnId } = useWatch()
  const addPage = location.pathname.toString().indexOf("add") != -1 ? true : false
  const [count, setCount] = useState(0)


  useEffect(() => {
    const fileForUserDetails = FileForUserGetUserDetails()

    if (fileForUserDetails) {
      const { fileForUserId, fileForUserPartyId, fileForUserLoginId } =
        fileForUserDetails

      setValue('fileForUserId', fileForUserId)
      setValue('fileForUserPartyId', fileForUserPartyId)
      setValue('fileForUserLoginId', fileForUserLoginId)
    }
  }, [])

  useEffect(() => {
    console.log("useEffect: ", JSON.stringify(ccnList));
    if (ccnList && ccnList.length === 0) {
      const { initialValues } = CCNFormProperties.formikProps
      setValue('ccnList', [{ ...initialValues, _ccnId: 1 }])
    }
  }, [ccnList])

  function handleAddCCN(data) {
    console.log("add data: ", JSON.stringify(data));
    const filteredCcnList = [...getValues('ccnList'), data].map((ccn, index) => ({ ...ccn, _ccnId: index + 1 }));
    console.log("add filteredCcnList: ", JSON.stringify(filteredCcnList));
    setValue('ccnList', filteredCcnList)
  }
  

  function handleDeleteCCN(data) {
    console.log("delete data: ", JSON.stringify(data));
    
    const ccnList = getValues('ccnList'); // Get the current list
    
    // Filter out the deleted item and update _ccnId
    const filteredCcnList = ccnList.filter((ccn) => ccn._ccnId !== data._ccnId)
      .map((ccn, index) => ({ ...ccn, _ccnId: index + 1 }));
  
    console.log("delete filteredCcnList: ", JSON.stringify(filteredCcnList));
    
    setValue('ccnList', filteredCcnList); // Update the list in the state
  }

  function makeTranslatedTextsObject() {
    let arrivalCert = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.TITLE
    )
    let id = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.ID
    )
    let ccn = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.CCN
    )
    let arrivalDateTime = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.ARRIVAL_DATE_TIME
    )
    let arrivalTimeZone = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.ARRIVAL_TIME_ZONE
    )
    let destinationOfficeCode = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.DESTINATION_OFFICE_CODE
    )
    let warehouseCode = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.WAREHOUSE_CODE
    )
    let messageFunction = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.MESSAGE_FUNCTION
    )
    let businessNumber = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.BUSINESS_NUMBER
    )
    let status = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.STATUS
    )
    let remarks = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.REMARKS
    )
    let modeOfSubmission = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.MODE_OF_SUBMISSION
    )
    let partyId = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.PARTY_ID
    )
    let submittedBy = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.SUBMITTED_BY
    )
    let submittedDate = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.SUBMITTED_DATE
    )
    let createdBy = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.CREATED_BY
    )
    let createdDate = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.CREATED_DATE
    )
    let updatedBy = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.UPDATED_BY
    )
    let updatedDate = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.UPDATED_DATE
    )
    let version = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.VERSION
    )
    let businessNumFlag = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.BUSINESS_NUM_FLAG
    )

    return {
      arrivalCert,
      id,
      ccn,
      arrivalDateTime,
      arrivalTimeZone,
      destinationOfficeCode,
      warehouseCode,
      messageFunction,
      businessNumber,
      status,
      remarks,
      modeOfSubmission,
      partyId,
      submittedBy,
      submittedDate,
      createdBy,
      createdDate,
      updatedBy,
      updatedDate,
      version,
      businessNumFlag
    }
  }

  return (
    <CngSection title={translatedTextsObject.arrivalCert}>
      <Grid container spacing={2}>
        {addPage && ccnList && <Grid item xs={12}>
          <CngSection title="Cargo Control Number List">
            <Grid container spacing={1}>
              {ccnList.map((ccn, index) => (
                <React.Fragment key={ccn._ccnId || index}>
                  <Grid item xs={12}>
                    <CCNEntry
                      index={index}
                      isLast={ccnList.length === 1}
                      onAddCCN={handleAddCCN}
                      onDeleteCCN={handleDeleteCCN}
                      ccn={ccn}
                      showNotification={showNotification}
                      shouldHideMap={shouldHideMap}
                    />
                  </Grid>
                  {ccnList.length !== index + 1 && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          </CngSection>
        </Grid>}
        <Grid item xs={12}>
          <Grid container spacing={1}>
            {!addPage && <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.ccn}>
              <CngTextField
                required
                name="ccn"
                label={translatedTextsObject.ccn}
                disabled={disabled}
                onChange={(e) => {
                  setValue("ccn", e.target.value.toUpperCase())
                }}
              />
            </CngGridItem>}
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.arrivalDateTime}>
              <CngDateTimeField
                required
                name="arrivalDateTime"
                label={translatedTextsObject.arrivalDateTime}
                format="YYYY-MM-DD HH:mm"
                disabled={disabled}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.arrivalTimeZone}>
              <CngCodeMasterAutocompleteField
                name='arrivalTimeZone'
                label={translatedTextsObject.arrivalTimeZone}
                disabled={disabled}
                codeType='SCHEDULER_TIME_ZONE'
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.destinationOfficeCode}>
              <NaCustomsOfficeAutocompleteField
                required
                name="destinationOfficeCode"
                label={translatedTextsObject.destinationOfficeCode}
                disabled={disabled}
                inputProps={
                  { maxLength: 30 }
                }
                lookupProps={{
                  filters: [{
                    field: 'indicator',
                    operator: 'equal',
                    value: 'ACI'
                  }]
                }}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.warehouseCode}>
              <NaSublocationWithoutCodeAutocompleteField
                name="warehouseCode"
                label={translatedTextsObject.warehouseCode}
                disabled={disabled}
                lookupProps={{
                  filters: [{
                    field: 'indicator',
                    operator: 'equal',
                    value: 'ACI'
                  }]
                }}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.messageFunction}>
              <CngCodeMasterAutocompleteField
                name='messageFunction'
                label={translatedTextsObject.messageFunction}
                disabled={disabled}
                codeType='ACI_HW_IBA_MSG_FUNC'
              />
            </CngGridItem>
          </Grid>
        </Grid>
        <CngGridItem xs={12} shouldHide={shouldHideMap.businessNumFlag}>
          <CollapsibleSection
            defaultExpanded={businessNumFlag}
            onExpandedChange={(value) => {
              if (!value) {
                setValue('businessNumber', '', { shouldValidate: true })
              }
            }}
            title={translatedTextsObject.businessNumFlag}
          >
            <Grid container spacing={1}>
              <CngGridItem xs={12} shouldHide={shouldHideMap.businessNumber}>
                <CngTextField
                  name="businessNumber"
                  label={translatedTextsObject.businessNumber}
                  disabled={disabled}
                  size='small'
                />
              </CngGridItem>
            </Grid>
          </CollapsibleSection>
        </CngGridItem>
      </Grid>
    </CngSection>
  )
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);

  if (localData.businessNumber !== undefined && localData.businessNumber !== '') {
    localData.businessNumFlag = true;
  }
  // localData.arrivalDateTime = DateTimeFormatter.toClientDate(
  //   localData.arrivalDateTime
  // );
  localData.submittedDate = DateTimeFormatter.toClientDate(
    localData.submittedDate
  );
  localData.createdDate = DateTimeFormatter.toClientDate(
    localData.createdDate
  );
  localData.updatedDate = DateTimeFormatter.toClientDate(
    localData.updatedDate
  );
  return localData;
}

function toServerDataFormat(localData) {
  // localData.arrivalDateTime = DateTimeFormatter.toServerDate(
  //   localData.arrivalDateTime
  // );
  localData.submittedDate = DateTimeFormatter.toServerDate(
    localData.submittedDate
  );
  localData.createdDate = DateTimeFormatter.toServerDate(
    localData.createdDate
  );
  localData.updatedDate = DateTimeFormatter.toServerDate(
    localData.updatedDate
  );

  return localData;
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
