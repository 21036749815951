import { BasePage as CngBasePage } from 'cng-web-lib'
import React from 'react'
import TablePage from './TablePage.js'
import useBreadcrumbNameMap from 'src/useBreadcrumbNameMap'
import { ErrorLogLookupsContext } from '../lookups/ErrorLogLookupsContext'

function WrappedTablePage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <ErrorLogLookupsContext.Provider>
      <CngBasePage
        renderPage={(showSnackbar) => (
          <TablePage showNotification={showSnackbar} {...props} />
        )}
        extraCngBreadcrumbNameMap={breadcrumbNameMap}
      />
    </ErrorLogLookupsContext.Provider>
  )
}

export { WrappedTablePage as TablePage }
