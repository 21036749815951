import { Container, Grid, Paper } from '@material-ui/core'
import React, { useState } from 'react'
import CargoEditForm from './CargoEditPage'
import Namespace from 'src/constants/locale/Namespace'
import { components, useTranslation } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import CargoKeys from 'src/constants/locale/key/AciHighwayCargo'
import ResponseMessage from '../response/ResponseMessage'

const { CngTabs } = components

function EditPage(props) {
  const { location, showNotification } = props
  const { state } = location
  const { id } = useParams()
  const { translate } = useTranslation([Namespace.ACI_HIGHWAY_CARGO])
  const translatedTextsObject = makeTranslatedTextsObject()

  const [ccnValue , setCCNValues ] = useState('')
  function setCCNValue(ccnValue){
    setCCNValues(ccnValue)
  }

  const [manifestId , setManifestIds ] = useState('')
  function setManifestId(manifestId){
    setManifestIds(manifestId)
  }

  function makeTranslatedTextsObject() {
    let editCargo = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      CargoKeys.EDIT_CARGO
    )
    let cargoResponse= translate(
      Namespace.ACI_HIGHWAY_CARGO,
      CargoKeys.RESPONSE_TITLE
    )

    return {
      editCargo,
      cargoResponse
    }
  }


  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngTabs activeTabIndex={state?.active ? state.active : 0}
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.editCargo,
              tabContent: (
                <CargoEditForm
                  showNotification={showNotification}
                  id={id}
                  setCCNValue={setCCNValue}
                  setManifestId={setManifestId}
                />
              )
            },
            {
              tabName: translatedTextsObject.cargoResponse,
              tabContent: (
                <ResponseMessage
                  showNotification={showNotification}
                  id={id}
                  isViewOnly={true}
                  ccnValue={ccnValue}
                  manifestId={manifestId}
                  cargoPage={true}
                  manifestPage={false}
                />
              )
            }
          ]}
        />
      </Grid>
    </Grid>
  );
}

export default EditPage
