import React, { useCallback } from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import AciHighwayManifestKeys from '../../../constants/locale/key/AciHighwayManifest'
import AciHighwayCargoKeys from '../../../constants/locale/key/AciHighwayCargo'
import AciHighwayResponseKeys from '../../../constants/locale/key/AciHighwayResponse'
import AciHighwayStatusQueryKeys from '../../../constants/locale/key/AciHighwayStatusQuery'
import AciHwReleaseNoticeKeys from '../../../constants/locale/key/AciHwReleaseNotice'

const ACIHighwayTranslationContext = React.createContext()

const ACIHighwayTranslationConsumer = ACIHighwayTranslationContext.Consumer

const ACIHighwayTranslationProvider = ({ children }) => {
    const { translate } = useTranslation([
        Namespace.ACIHWY_MANIFEST,
        Namespace.ACI_HIGHWAY_CARGO
    ])

    const translation = {
        ACI_HIGHWAY_CARGO: {
            aciHighway: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.TITLE),
            cargoType: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.CARGO_TYPE),
            consolidated: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.CONSOLIDATED),
            carrierCode: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.CARRIER_CODE),
            cargoLoadedIn: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.CARGO_LOADED_IN),
            ccn: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.CCN),
            firstExpectedPort: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.FIRST_EXPECTED_PORT),
            arrivalDate: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.ARRIVAL_DATE),
            arrivalTime: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.ARRIVAL_TIME),
            arrivalDateAndTimeZone: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.ARRIVAL_DATE_AND_TIME_ZONE),
            entryNo: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.ENTRY_NO),
            paperRelease: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.PAPER_RELEASE),
            inbondDestinationOfficeCode: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.INBOND_DESTINATION_OFFICE_CODE),
            warehouseCode: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.WAREHOUSE_CODE),
            polCity: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.POL_CITY),
            polCountryCode: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.POL_COUNTRY_CODE),
            polStateCode: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.POL_STATE_CODE),
            poaCity: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.POA_CITY),
            poaCountryCode: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.POA_COUNTRY_CODE),
            poaStateCode: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.POA_STATE_CODE),
            adminName: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.ADMIN_NAME),
            adminContact: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.ADMIN_CONTACT),
            specialInstructions: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.SPECIAL_INSTRUCTIONS),
            portOfExit: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.PORT_OF_EXIT),
            acquittalNo: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.ACQUITTAL_NO),
            printDate: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.DATE),
            printRemarks: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.PRINT_REMARKS),
            messageFunction: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.MESSAGE_FUNCTION),
            status: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.STATUS),
            tripNo: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.TRIP_NO),
            templateFlag: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.TEMPLATE_FLAG),
            templateName: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.TEMPLATE_NAME),
            saveButton: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.SAVE_BUTTON),
            updateButton: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.UPDATE_BUTTON),
            cargoCommodity: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.CargoCommodity.TITLE),
            productDesc: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.CargoCommodity.PRODUCT_DESC),
            quantity: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.CargoCommodity.QUANTITY),
            quantityUOM: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.CargoCommodity.QUANTITY_U_O_M),
            weight: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.CargoCommodity.WEIGHT),
            weightUom: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.CargoCommodity.WEIGHT_UOM),
            hsCode: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.CargoCommodity.HS_CODE),
            marksNumber: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.CargoCommodity.MARKS_NUMBER),
            _cargoCommodityId: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.HazardousMaterial.CARGO_COMMODITY),
            undgCode: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.HazardousMaterial.UNDG_CODE),
            undgDesc: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.HazardousMaterial.UNDG_DESC),
            placeOfLoadingTitle: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.PLACE_OF_LOADING_TITLE),
            placeOfAcceptanceTitle: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.PLACE_OF_ACCEPTANCE_TITLE),
            adminstrativeContact: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.ADMINSTRATIVE_CONTACT),
            printA8A: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.PRINT_A8A),
            amendmentCode: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.AMENDMENT_CODE),
            cargoSectionHelper: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.CARGO_SECTION_HELPER),
            commoditySectionHelper: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.COMMODITY_SECTION_HELPER),

            cargoParties: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.CargoParties.TITLE),
            partyType: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.CargoParties.PARTY_TYPE),
            partyDropDown: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.CargoParties.PARTY_DROP_DOWN),
            saveFlag: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.CargoParties.SAVE_FLAG),
            partyIdentification: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.CargoParties.PARTY_IDENTIFICATION),
            partyName: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.CargoParties.PARTY_NAME),
            businessNo: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.CargoParties.BUSINESS_NO),
            address: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.CargoParties.ADDRESS),
            city: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.CargoParties.CITY),
            postalCode: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.CargoParties.POSTAL_CODE),
            countryCode: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.CargoParties.COUNTRY_CODE),
            stateCode: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.CargoParties.STATE_CODE),
            adminName: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.CargoParties.ADMIN_NAME),
            adminContact: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.CargoParties.ADMIN_CONTACT),
            adminstrativeContact: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.ADMINSTRATIVE_CONTACT),
            phoneNo: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.PHONE_NO),
            faxNo: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.FAX_NO),
            email: translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.EMAIL)
        }
    }

    const getTranslatedText = useCallback((namespace, key) => {
        if (!namespace || !key) return undefined

        return translation?.[namespace]?.[key] || undefined
    }, [])

    return (
        <ACIHighwayTranslationContext.Provider value={{ translation, getTranslatedText }}>
            {children}
        </ACIHighwayTranslationContext.Provider>
    )
}

export default ACIHighwayTranslationProvider

export { ACIHighwayTranslationContext, ACIHighwayTranslationConsumer }
