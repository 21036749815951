import { components, constants, useTranslation, useServices, useWhiteLabelServiceSettings } from 'cng-web-lib'
import AciHighwayCargoApiUrls from 'src/apiUrls/AciHighwayCargoApiUrls'
import NaCodeMaintenanceApiURLs from 'src/apiUrls/NaCodeMaintenanceApiUrls'
import React, { useEffect, useRef, useState } from 'react'
import AciHighwayCargoKeys from 'src/constants/locale/key/AciHighwayCargo'
import Namespace from 'src/constants/locale/Namespace'
import pathMap from 'src/paths/pathMap'
import moment from 'moment-timezone'
import Table from '../../../components/aciacehighway/Table'
import TinyChip from '../../../components/aciacehighway/TinyChip'
import ConfirmDialog from '../../common/ConfirmDialog'
import { Box, Chip, Typography, Tooltip, useTheme, Grid } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import { getStatusMetadata } from '../../../common/NACommon'
import { FileForUserGetPartyId,FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import sweetalert from 'sweetalert'
import TemplateSelectDialogWithOptionACIHWYCargo from 'src/components/aciacehighway/TemplateSelectDialogWithOptionACIHWYCargo'
import ResponseMessage from 'src/views/acihighway/response/ResponseMessage.js'


const {
  button: { CngButton },
  table: { useFetchCodeMaintenanceLookup },
  CngDialog
} = components

const {
  locale: {
    key: { UiComponentKeys }
  },
  filter: { BETWEEN, EQUAL, IN, LIKE }
} = constants

function TablePage(props) {
  const { history, location, showNotification } = props
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { createRecord, deleteRecord, fetchRecords, securedSendRequest } = useServices()
  const [cargoTemplateDialog, setCargoTemplateDialog] = useState(false)

  const [lookups, setLookups] = useState(null)
  const [confirmDialog, setConfirmDialog] = useState({ open: false, cargo: null })
  const [massDeleteConfirmDialog, setMassDeleteConfirmDialog] = useState({ open: false, idList: null })
  const theme = useTheme()
  const tableRef = useRef(null)

  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.ACI_HIGHWAY_CARGO
  ])
  const translatedTextsObject = makeTranslatedTextsObject()
  const whiteLabelServiceSettings = useWhiteLabelServiceSettings("aci-highway")
  const [quickGlanceResponseLogDialog, setQuickGlanceResponseLogDialog] = useState({
    open: false,
    cargo: null
  })
  useEffect(() => {
    Promise.all([
      // Status
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACIHWY_STATUS' }],
        undefined,
        'code'
      ),
      // First expected port
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET,
        {
          filters: [
            { field: 'indicator', operator: EQUAL, value: 'ACI' }
          ],
          customData: { codeMType: 'CustomsOffice' }
        },
        (res) => {
          const result = {}

          res.content.forEach((item) => {
            result[item.code] = item.descriptionEn
          })

          return result
        }
      ),
      // Expected Time Zone
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [
          {
            field: 'codeType',
            operator: EQUAL,
            value: 'SCHEDULER_TIME_ZONE'
          }
        ],
        undefined,
        'code'
      ),
      // Arrival date time zone
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'SCHEDULER_TIME_ZONE' }],
        undefined,
        'code'
      )
    ]).then(([status, firstExpectedPort, timeZone, arrivalDateAndTimeZone, tripNo]) => {
      setLookups({
        status,
        firstExpectedPort,
        timeZone,
        arrivalDateAndTimeZone,
        tripNo
      })
    })
  }, [])

  function makeTranslatedTextsObject() {
    let aciHighway = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: aciHighway
      }
    )
    let cargoType = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CARGO_TYPE
    )
    let attachSentRecord = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.ATTACH_SENT
    )
    let attach = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.ATTACH
    )
    let consolidated = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CONSOLIDATED
    )
    let carrierCode = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CARRIER_CODE
    )
    let ccn = translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.CCN)
    let firstExpectedPort = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.FIRST_EXPECTED_PORT
    )
    let arrivalDateAndTime = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.ARRIVAL_DATE_AND_TIME
    )
    let arrivalDateAndTimeZone = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.ARRIVAL_DATE_AND_TIME_ZONE
    )
    let entryNo = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.ENTRY_NO
    )
    let paperRelease = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.PAPER_RELEASE
    )
    let inbondDestinationOfficeCode = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.INBOND_DESTINATION_OFFICE_CODE
    )
    let warehouseCode = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.WAREHOUSE_CODE
    )
    let polCity = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.POL_CITY
    )
    let polCountryCode = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.POL_COUNTRY_CODE
    )
    let polStateCode = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.POL_STATE_CODE
    )
    let poaCity = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.POA_CITY
    )
    let poaCountryCode = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.POA_COUNTRY_CODE
    )
    let poaStateCode = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.POA_STATE_CODE
    )
    let adminName = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.ADMIN_NAME
    )
    let adminContact = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.ADMIN_CONTACT
    )
    let specialInstructions = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.SPECIAL_INSTRUCTIONS
    )
    let portOfExit = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.PORT_OF_EXIT
    )
    let acquittalNo = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.ACQUITTAL_NO
    )
    let date = translate(Namespace.ACI_HIGHWAY_CARGO, AciHighwayCargoKeys.DATE)
    let printRemarks = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.PRINT_REMARKS
    )
    let messageFunction = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.MESSAGE_FUNCTION
    )
    let status = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.STATUS
    )
    let reviewStatus = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.REVIEW_STATUS
    )
    let tripNo = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.TRIP_NO
    )
    let editMultipleRecords = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.EDIT_MULTIPLE_RECORDS
    )
    let editSentRecords = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.EDIT_SENT_RECORDS
    )
    let editButton = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.EDIT_BUTTON
    )
    let viewButton = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.VIEW_BUTTON
    )
    let cloneButton = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CLONE_BUTTON
    )
    let cancelButton = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CANCEL_BUTTON
    )
    let cancel = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CANCEL
    )
    let submitButton = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.SUBMIT_BUTTON
    )
    let deleteButton = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.DELETE_BUTTON
    )
    let viewMultipleRecords = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.VIEW_MULTIPLE_RECORDS
    )
    let cloneMultipleRecords = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CLONE_MULTIPLE_RECORDS
    )
    let deleteMultipleRecords = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.DELETE_MULTIPLE_RECORDS
    )
    let submitSentRecord = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.SUBMIT_SENT_RECORD
    )
    let submitSuccessMsg = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.SUBMIT_SUCCESS_MSG
    )
    let submitErrorMsg = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.SUBMIT_ERROR_MSG
    )
    let submitRecordDialogContent = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.SUBMIT_RECORD_DIALOG_CONTENT
    )
    let submitRecord = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.SUBMIT_RECORD
    )
    let createdDateTime = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CREATIONDATETIME
    )
    let submittedDate = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.SUBMITTED_DATE_TIME
    )
    let attachCargoMessage = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.ATTACH_CARGO_MSG
    )
    let attachCargoSuccessMsg = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.ATTACH_CARGO_SUCESS_MSG
    )
    let printA8A = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.PRINT_A8A
    )
    let printA49 = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.PRINT_A49
    )
    let printMultipleRecords = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.PRINT_MULTIPLE_RECORDS
    )
    let consigneeName = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CONSIGNEE_NAME
    )
    let shipperName = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.SHIPPER_NAME
    )

    return {
      aciHighway,
      tableTitle,
      cargoType,
      consolidated,
      carrierCode,
      ccn,
      cancel,
      attachCargoMessage,
      attachCargoSuccessMsg,
      firstExpectedPort,
      arrivalDateAndTime,
      arrivalDateAndTimeZone,
      entryNo,
      paperRelease,
      inbondDestinationOfficeCode,
      warehouseCode,
      polCity,
      polCountryCode,
      polStateCode,
      poaCity,
      poaCountryCode,
      poaStateCode,
      adminName,
      adminContact,
      specialInstructions,
      portOfExit,
      acquittalNo,
      date,
      printRemarks,
      messageFunction,
      status,
      reviewStatus,
      tripNo,
      attachSentRecord,
      attach,
      editMultipleRecords,
      editSentRecords,
      editButton,
      viewButton,
      cloneButton,
      cancelButton,
      submitButton,
      deleteButton,
      viewMultipleRecords,
      cloneMultipleRecords,
      deleteMultipleRecords,
      submitSentRecord,
      submitSuccessMsg,
      submitErrorMsg,
      submitRecordDialogContent,
      submitRecord,
      createdDateTime,
      submittedDate,
      printA8A,
      printA49,
      printMultipleRecords,
      consigneeName,
      shipperName,
    }
  }

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  let columns = [
    {
      field: 'cargoType',
      sortKey: 'cargoType',
      title: translatedTextsObject.cargoType,
      render: (data) => (
        data.cargoType === "OSC" ? "OTHER" : data.cargoType
      )
    },
    {
      field: 'ccn',
      sortKey: 'ccn',
      title: translatedTextsObject.ccn
    },
    {
      field: 'firstExpectedPort',
      sortKey: 'firstExpectedPort',
      title: translatedTextsObject.firstExpectedPort,
      render: (data) =>
        <Typography component='div' variant='inherit'>
          {getLookupValue('firstExpectedPort', data.firstExpectedPort)}
          <TinyChip label={data.firstExpectedPort} variant='outlined' />
        </Typography>
    },
    {
      field: 'arrivalDateAndTime',
      sortKey: 'arrivalDateAndTime',
      title: `${translatedTextsObject.arrivalDateAndTime} & ${translatedTextsObject.arrivalDateAndTimeZone}`,
      render: (data) =>
        data.arrivalDateAndTime && (
          <Typography component='div' variant='inherit'>
            {data.arrivalDateAndTime &&
              moment(data.arrivalDateAndTime).format('D MMM YYYY, HH:mm')}
            {data.arrivalDateAndTimeZone && (
              <TinyChip label={data.arrivalDateAndTimeZone} variant='outlined' />
            )}
          </Typography>
        )
    },
    {
      field: 'shipperName',
      sortKey: 'shipperName',
      title: translatedTextsObject.shipperName
    },
    {
      field: 'consigneeName',
      sortKey: 'consigneeName',
      title: translatedTextsObject.consigneeName
    },
    {
      field: 'createdDate',
      sortKey: 'createdDate',
      title: translatedTextsObject.createdDateTime,
      render: (data) =>
        data.createdDate && moment(data.createdDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: 'submittedDate',
      sortKey: 'submittedDate',
      title: translatedTextsObject.submittedDate,
      render: (data) => data.submittedDate && moment(data.submittedDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: 'entryNo',
      sortKey: 'entryNo',
      title: translatedTextsObject.entryNo
    },
    {
      field: 'status',
      sortKey: 'status',
      title: translatedTextsObject.status,
      render: (data) => {
        const status = getStatusMetadata(data.status)

        if (data.status == '1000' && data.cargoType == 'REGULAR' && !data.entryNo) {
          status.color = "#ffc400"
          status.contrastColor = "#000000"
        }

        return (
          <Box display='flex' alignItems='baseline' flexDirection='column' style={{ gap: 4 }}>
            <Tooltip key='more' title='View response(s)' placement='top'>
              <Chip
                label={status.label}
                size='small'
                style={{
                  backgroundColor: status.color,
                  color: status.contrastColor
                }}
                clickable
                onClick={(event) => {
                  event.stopPropagation()
                  setQuickGlanceResponseLogDialog({ open: true, cargo: data })
                }}
              />
            </Tooltip>
            {data.portMismatch && (
              <Chip
                label='Port Mismatch'
                size='small'
                style={{ color: '#ffffff', backgroundColor: '#DF2901' }}
              />
            )}
          </Box>
        )
      }
    },
    {
      field: 'tripNo',
      sortKey: 'tripNo',
      title: translatedTextsObject.tripNo,
      render: (data) => {
        var editUrl = `${'/aci-highway/manifest'}/edit/${data.manifestId}`

        return (<div style={{ 'text-decoration': 'underline', cursor: 'pointer' }} onClick={(event) => {
          event.stopPropagation()
          history.push(editUrl)
        }}>{data.tripNo}</div>)
      }
    }
  ]

  let challengerColumns = [
    {
      field: 'apiAutoSubmit',
      sortKey: 'reviewStatus',
      title: translatedTextsObject.reviewStatus,
      render: (data) => {
        if (data.apiAutoSubmit) {
          const reviewStatus = getStatusMetadata(data.apiAutoSubmit)

          return (
            <>
              <Chip
                label={reviewStatus.label}
                size='small'
                style={{
                  backgroundColor: reviewStatus.color,
                  color: reviewStatus.contrastColor
                }}
              />
            </>
          )
        }
      }
    }
  ]

  if (whiteLabelServiceSettings && whiteLabelServiceSettings.length > 0
    && whiteLabelServiceSettings[0].custom.UI_MANIFEST_CARGO_TABLE_REVIEW_STATUS === 'true') {
    columns = [...columns, ...challengerColumns]
  }

  const filters = [
    {
      label: translatedTextsObject.ccn,
      type: 'textfield',
      name: 'ccn',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.firstExpectedPort,
      type: 'textfield',
      name: 'firstExpectedPort',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.arrivalDateAndTime,
      type: 'daterange',
      name: 'arrivalDateAndTime',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.shipperName,
      type: 'textfield',
      name: 'shipperName',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.consigneeName,
      type: 'textfield',
      name: 'consigneeName',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.tripNo,
      type: 'textfield',
      name: 'tripNo',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.entryNo,
      type: 'textfield',
      name: 'entryNo',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.createdDate,
      type: 'daterange',
      name: 'createdDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.submittedDate,
      type: 'daterange',
      name: 'submittedDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.status,
      type: 'checkbox',
      name: 'status',
      operatorType: IN,
      options: [
        {
          label: 'Draft',
          value: 'draft',
          filterValue: {
            value: '1005'
          }
        },
        {
          label: 'Accepted',
          value: 'accepted',
          filterValue: {
            value: '1000'
          }
        },
        {
          label: 'Pending Entry Number',
          value: 'pending',
          filterValue: {
            value: 'PENDING'
          }
        },
        {
          label: 'Sent',
          value: 'sent',
          filterValue: {
            value: '1008'
          }
        },
        {
          label: 'Rejected',
          value: 'rejected',
          filterValue: {
            value: '1001'
          }
        },
        {
          label: 'Template',
          value: 'Template',
          filterValue: {
            value: '1002'
          }
        },
        {
          label: 'Status Notification',
          value: 'status_notification',
          filterValue: {
            value: '1006'
          }
        },
        {
          label: 'Cancellation Accepted',
          value: 'cancellation_accepted',
          filterValue: {
            value: '1003'
          }
        },
        {
          label: 'Cancellation Rejected',
          value: 'cancellation_rejected',
          filterValue: {
            value: '1004'
          }
        },
        {
          label: 'Modification Rejected',
          value: 'modification_rejected',
          filterValue: {
            value: '1007'
          }
        }
      ]
    },
    {
      label: translatedTextsObject.reviewStatus,
      type: 'checkbox',
      name: 'apiAutoSubmit',
      operatorType: IN,
      options: [
        {
          fullWidth: true,
          label: 'Pending Review',
          value: 'pending_review',
          filterValue: {
            value: 'R'
          }
        },
        {
          fullWidth: true,
          label: 'Review in Progress',
          value: 'review_in_progress',
          filterValue: {
            value: 'Y'
          }
        },
        {
          fullWidth: true,
          label: 'Reviewed',
          value: 'reviewed',
          filterValue: {
            value: 'G'
          }
        }
      ]
    },
    {
      label: translatedTextsObject.cargoType,
      type: 'checkbox',
      name: 'cargoType',
      operatorType: IN,
      options: [
        {
          label: 'PARS/Regular',
          value: 'REGULAR',
          filterValue: {
            value: 'REGULAR'
          }
        },
        {
          label: 'In-bond',
          value: 'IN-BOND',
          filterValue: {
            value: 'IN-BOND'
          }
        },
        {
          label: 'CSA (Customs Self Assessment)',
          value: 'CSA',
          filterValue: {
            value: 'CSA'
          }
        },
        {
          label: 'Other System Cargo',
          value: 'OSC',
          filterValue: {
            value: 'OSC'
          }
        }
      ]
    }
  ]

  function handleCloneCargo(data) {
    data.cloneFrom = data.ccn;
    createRecord.execute(
      AciHighwayCargoApiUrls.CLONE,
      data,
      (data) => {
        const commodityData = data.cargoCommodity.map(
          (cargoCommodity, index) => ({ ...cargoCommodity, _id: index + 1 })
        )
        data.cargoCommodity = commodityData

        const partyData = data.cargoParties.map(
          (cargoParties, index) => ({ ...cargoParties, _id: index + 1 })
        )
        data.cargoParties = partyData

        data.arrivalTime = moment(data.arrivalDateAndTime).format('HH:mm');
        data.arrivalDate = moment(data.arrivalDateAndTime).format('YYYY-MM-DD');

        history.push(pathMap.ACI_HW_CARGO_ADD_VIEW, data)
      },
      (error) => {
        console.log(error)
      }
    )
  }

  function handleMassDeleteCargo() {
    if (massDeleteConfirmDialog.idList) {
      setMassDeleteConfirmDialog({ open: false })
      sweetalert("Cargo(s) deletion in progress...", "", "success");
      securedSendRequest.execute('POST',
        AciHighwayCargoApiUrls.MASS_DELETE,
        massDeleteConfirmDialog.idList,
        () => {
          showNotification('success', 'Mass delete succeeded')
          setMassDeleteConfirmDialog({ open: false, idList: null })
          tableRef.current.performRefresh()
        },
        (error) => {
          showNotification('error', 'Something went wrong. Mass delete failed.')
          console.log(error)
        }
      )
    }
  }

  function handleDeleteCargo() {
    if (confirmDialog.cargo) {
      deleteRecord.execute(
        AciHighwayCargoApiUrls.DELETE,
        confirmDialog.cargo,
        () => {
          showNotification('success', 'Delete succeeded')
          setConfirmDialog({ open: false, cargo: null })
          setCargoTemplateDialog(false);
          tableRef.current.performRefresh()
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }

  function handlePrintA8A(data) {
    const config = { responseType: 'blob' }
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      data.fileForUserId = fileForUserDetails.fileForUserId;
      data.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      data.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }
    securedSendRequest.execute(
      'POST',
      AciHighwayCargoApiUrls.PRINT,
      data,
      (response) => {
        let blob = new Blob([response.data])

        let first = 'ACIHW_CARGO_A8A_'
        let curDate = format(new Date(), 'yyyyMMddHHmmss')
        let filename = first + curDate + '.pdf'
        let url = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url
        a.download = filename
        a.click()
      },
      (error) => console.error(error),
      undefined,
      config,
      null
    )
  }

  function handlePrintA49(data) {
    const config = { responseType: 'blob' }
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      data.fileForUserId = fileForUserDetails.fileForUserId;
      data.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      data.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }
    securedSendRequest.execute(
      'POST',
      AciHighwayCargoApiUrls.PRINTA49,
      data,
      (response) => {
        let blob = new Blob([response.data])

        let first = 'ACIHW_CARGO_A49_'
        let curDate = format(new Date(), 'yyyyMMddHHmmss')
        let filename = first + curDate + '.pdf'
        let url = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url
        a.download = filename
        a.click()
      },
      (error) => console.error(error),
      undefined,
      config,
      null
    )
  }

  async function exportCargo(data) {
    if (data.length > 0) {
      const config = { responseType: 'blob' }

      securedSendRequest.execute('POST', AciHighwayCargoApiUrls.EXPORT_CARGO, data,
        (response) => {
          let blob = new Blob([response.data])

          let first = 'ACIHW_CARGO_EXPORT_'
          let curDate = format(new Date(), 'yyyyMMddHHmmss')
          let filename = first + curDate + '.csv'
          let url = window.URL.createObjectURL(blob)
          let a = document.createElement('a')
          a.href = url
          a.download = filename
          a.click()
        },
        (error) => console.error(error),
        undefined,
        config,
        null
      )
    }
  }

  return (
    <>
      <Table
        actions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => history.push(pathMap.ACI_HW_CARGO_ADD_VIEW)
            },
            label: 'Create cargo'
          },
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => setCargoTemplateDialog(true)
            },
            label: 'Cargo Template'
          }
        ]}
        columns={columns}
        compact
        exportData={{ url: AciHighwayCargoApiUrls.EXPORT }}
        fetch={{ url: AciHighwayCargoApiUrls.GET }}
        fetchFilters={[
          { field: 'templateFlag', operator: EQUAL, value: '0' },
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        filters={filters}
        onRowClick={(rowData) => {
          if (rowData.status === '1008') {
            history.push(`${location.pathname}/view/${rowData.id}`)
          }
          else {
            history.push(`${location.pathname}/edit/${rowData.id}`)
          }
        }
        }
        rowActions={[
          {
            disabled: (rowData) => rowData.status === '1008',
            icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
            label: translatedTextsObject.editButton,
            onClick: (rowData) =>
              history.push(`${location.pathname}/edit/${rowData.id}`),
            tooltip: (rowData) =>
              rowData.status === '1008'
                ? translatedTextsObject.editSentRecords
                : null
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'copy']} />,
            label: translatedTextsObject.cloneButton,
            onClick: handleCloneCargo
          },
          {
            disabled: (rowData) => rowData.status !== '1005',
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            label: translatedTextsObject.deleteButton,
            onClick: (rowData) => setConfirmDialog({ open: true, cargo: rowData }),
            tooltip: (rowData) =>
              rowData.status !== '1005'
                ? 'Only draft status can be deleted'
                : null
          },
          {
            label: translatedTextsObject.printA8A,
            icon: <FontAwesomeIcon icon={['fal', 'print']} />,
            onClick: handlePrintA8A
          },
          {
            label: translatedTextsObject.printA49,
            icon: <FontAwesomeIcon icon={['fal', 'print']} />,
            onClick: handlePrintA49
          }
        ]}
        showNotification={showNotification}
        sortConfig={{
          type: 'column',
          defaultDirection: 'DESC',
          defaultField: 'createdDate'
        }}
        persistSettings
        tableRef={tableRef}
        checkboxSelection
        selectActions={[
          {
            // disabled: (rows) => rows.length > 500,
            icon: <FontAwesomeIcon icon={['fal', 'link']} />,
            label: "Export Cargo(s)",
            onClick: (rows) => {
              if (rows.length > 500) {
                sweetalert("Maximum of 500 records per export.", "", "error");
              } else {
                sweetalert("Exporting Cargo in the background. The report will be downloaded automatically once ready.", "", "success");
                return exportCargo(rows);
              }
            },
            tooltip: (rows) => rows.length > 500 ? "Maximum of 500 records per export" : null
          },
          {
            disabled: (rows) => {
              let disable = false;
              rows.forEach(e => {
                if (e.status !== '1005') { disable = true }
              })
              return disable;
            },
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            label: "Mass Delete",
            tooltip: (rows) => {
              let description = null;
              rows.forEach(e => {
                if (e.status !== '1005') { description = 'Only draft status can be deleted' }
              })
              return description;
            },
            buttonProps: {
              size: 'medium',
              color: 'secondary',
              style: { color: theme.palette.error.main, minWidth: 'auto' }
            },
            onClick: (rows) => {
              let idList = rows.map(e => e.id)
              setMassDeleteConfirmDialog({ open: true, idList: idList });
            }
          }
        ]}
      />
      <TemplateSelectDialogWithOptionACIHWYCargo
        cargoTemplateDialog={cargoTemplateDialog}
        setCargoTemplateDialog={setCargoTemplateDialog}
        setConfirmDialog={setConfirmDialog}
        showNotification={showNotification}
        translatedTextsObject={translatedTextsObject}
        isTable={true}
        location={location}
        rowActions={[
          {
            icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
            label: 'Edit',
            onClick: (rowData) => {
              history.push(pathMap.ACI_HW_CARGO + `/edit/${rowData.id}`)
            },
            tooltip: () => 'edit'
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            label: 'Delete',
            onClick: (rowData) =>
              setConfirmDialog({ open: true, cargo: rowData }),
            tooltip: () => 'delete'
          }
        ]}
      />
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog.open}
        closeDialog={() => setConfirmDialog({ open: false, cargo: null })}
        confirmDialog={handleDeleteCargo}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={translatedTextsObject.deleteButton}
      />
      <ConfirmDialog
        isConfirmDialogOpen={massDeleteConfirmDialog.open}
        closeDialog={() => setMassDeleteConfirmDialog({ open: false, idList: null })}
        confirmDialog={handleMassDeleteCargo}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={translatedTextsObject.deleteButton}
      />
      <QuickGlanceResponseLogDialog
        getLookupValue={getLookupValue}
        cargo={quickGlanceResponseLogDialog.cargo}
        onClose={() => setQuickGlanceResponseLogDialog({ open: false, cargo: null })}
        open={quickGlanceResponseLogDialog.open}
        translatedTextsObject={translatedTextsObject}
      />
    </>
  )
}

export default TablePage
function QuickGlanceResponseLogDialog(props) {
  const { getLookupValue, cargo, onClose, open, translatedTextsObject } = props
    const { securedSendRequest } = useServices()
    const theme = useTheme()
    const [statusList, setStatusList] = useState([])
    const [redirectUrl, setRedirectUrl] = useState()
  
    const redirectData = {
      manifestData: {...cargo},
      step: "2"
    }
  
  let content = cargo ? (
      <Grid item xs={12}>
            <ResponseMessage
              id={cargo.id}
              isViewOnly={true}
              ccnValue={cargo.ccn}
              manifestId={cargo.manifestId}
              cargoPage={true}
              manifestPage={false}
            />     
        </Grid>
    ) : null
      return (
      <CngDialog
        dialogContent={content}
        dialogAction={<CngButton onClick={onClose}>OK</CngButton>}
        dialogTitle='Response log '
        fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
        fullWidth
        maxWidth='lg'
        onClose={onClose}
        open={open}
        shouldShowCloseButton
      />
    )
  
  }