import makeValidationSchema from './MakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import React, { useEffect, useState, useContext } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
} from 'cng-web-lib'
// import { AceIsfTranslationContext } from '../contexts/ACEISFTranslationContext'
import { useFormContext, useWatch } from 'react-hook-form';

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngCheckboxField,
      CngTextField,
      CngDateField,
      CngTimeField,
      CngCodeMasterAutocompleteField,
      CngCountryAutocompleteField,
      CngSelectField
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  _ccnId: 0,
  ccn: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap,
  index
}) {
  // const { getTranslatedText } = useContext(AceIsfTranslationContext)
  const { getValues, setValue, reset, trigger } = useFormContext();
  const templateFlag = useWatch({ name: 'templateFlag' });
  const isEditTemplate = useWatch({ name: 'isEditTemplate' });
  console.log("index: " + index)
  
  return (
    <Card>
      {/* <CngSimpleCardHeader title={translatedTextsObject.shipment} /> */}
      <CardContent>
        <Grid container spacing={2}>
          <CngGridItem xs={12} sm={12}>
            <CngTextField
              name={`ccnList.${index}.ccn`}
              label="Cargo Control Number"
              disabled={disabled || (isEditTemplate && templateFlag)}
              inputProps={{ maxLength: 50 }}
              onChange={(e) => {
                setValue(`ccnList.${index}.ccn`, e.target.value.toUpperCase())
              }}
              size='small'
            />

          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
  )
}

const CCNFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default CCNFormProperties
