import React, { useRef, useEffect } from 'react'
import { components } from 'cng-web-lib'
import { Box, Card, Grid, Tooltip } from '@material-ui/core'
import { useFormContext, useWatch } from 'react-hook-form'
import CngSection from 'src/components/cngcomponents/CngSection'
import CargoPartiesFormProperties from '../CargoPartiesFormProperties'

const { button: { CngIconButton } } = components

const { initialValues } = CargoPartiesFormProperties.formikProps

function CargoPartiesSection() {
    const { getValues, setValue } = useFormContext()
    const cargoType = useWatch({ name: "cargoType" });
    const cargoParties = useWatch({ name: 'cargoParties' })
    const city = useWatch({ name: 'polCity' })
    const countryCode = useWatch({ name: 'polCountryCode' })
    const stateCode = useWatch({ name: 'polStateCode' })
    const lastIndex = useRef(cargoParties.length - 1)

    // Push cargoParty default value with crew type 'Consignee' and 'Shipper' if there isn't any
    useEffect(() => {
        const { initialValues } = CargoPartiesFormProperties.formikProps

        if (cargoType !== 'OSC') {
            if (cargoParties.length === 0) {
                setValue('cargoParties', [
                    { ...initialValues, partyType: 'SH', _id: 1, city: city, countryCode: countryCode, stateCode: stateCode },
                    { ...initialValues, partyType: 'CN', _id: 2 }
                ])
            } else {
                const hasConsignee = cargoParties.findIndex((party) => party.partyType === 'CN') !== -1
                const hasShipper = cargoParties.findIndex((party) => party.partyType === 'SH') !== -1

                if (!hasConsignee || !hasShipper) {
                    const result = [...getValues('cargoParties')]

                    if (!hasShipper) {
                        result.push({ ...initialValues, partyType: 'SH', city: city, countryCode: countryCode, stateCode: stateCode })
                    } else if (!hasConsignee) {
                        result.push({ ...initialValues, partyType: 'CN' })
                    }

                    setValue('cargoParties', result.map((party, index) => ({ ...party, _id: index + 1 })))
                }
            }
        }
    }, [cargoParties])

    function handleAddCargoParty(data) {
        const cargoParties = [...getValues('cargoParties'), { ...data, _id: ++lastIndex.current }]
        setValue('cargoParties', cargoParties)
    }

    function handleDeleteCargoParty(data) {
        const cargoParties = [...getValues('cargoParties')].filter((cargoParty) =>
            cargoParty.id ? cargoParty.id !== data.id : cargoParty._id !== data._id
        )
        setValue('cargoParties', cargoParties)
    }

    return (
        <Grid item xs={12}>
            <CngSection title='Shipper / Consignee / Other Party'>
                <Grid container spacing={2}>
                    {cargoParties.map((party, index) => (
                        <Grid key={index} item xs={12}>
                            <Card variant='outlined'>
                                <Box padding={2}>
                                    <Grid container justify='space-between' spacing={1}>
                                        <Grid item xs={12}>
                                            <CargoPartiesFormProperties.Fields index={index} cargoTypes={cargoType} />
                                        </Grid>
                                        <Grid item xs='auto'>
                                            <Grid container spacing={1}>
                                                <Grid item>
                                                    <Tooltip placement='bottom' title='Add'>
                                                        <span>
                                                            <CngIconButton
                                                                icon={['fal', 'plus']} size='small' type='outlined'
                                                                onClick={() => handleAddCargoParty(initialValues)}
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item>
                                                    <Tooltip placement='bottom' title='Clone'>
                                                        <span>
                                                            <CngIconButton
                                                                icon={['fal', 'copy']} size='small' type='outlined'
                                                                onClick={() => handleAddCargoParty({ ...party, id: undefined })}
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item>
                                                    <Tooltip placement='bottom' title='Delete'>
                                                        <span>
                                                            <CngIconButton
                                                                icon={['fal', 'trash']} size='small' type='outlined'
                                                                onClick={() => handleDeleteCargoParty(party)}
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </CngSection>
        </Grid>
    )
}

export default CargoPartiesSection