import ArrivalCertApiUrls from 'src/apiUrls/ArrivalCertApiUrls'
import FormProperties from './FormProperties'
import { Box, Card, Grid } from '@material-ui/core'
import React, { useEffect } from 'react'
import { components, DataFlattener, useServices, useTranslation } from 'cng-web-lib'
import pathMap from 'src/paths/pathMap'
import Namespace from 'src/constants/locale/Namespace'
import ArrivalCertKeys from 'src/constants/locale/key/ArrivalCert'
import { useLocation } from "react-router-dom"
import withFormState from '../../../components/aciacehighway/hocs/withFormState'
import { useFormContext, useWatch } from 'react-hook-form'

const {
  button: { CngButton },
  form: { CngAddForm }
} = components

function AddPage({ history, onSetLoading, showNotification }) {
  const loc = useLocation()
  let formikPropsData
  const { translate } = useTranslation(Namespace.ARRIVAL_CERT)
  const translatedTextsObject = makeTranslatedTextsObject()

  if(loc.state !== undefined) { 
    formikPropsData = {
      ...FormProperties.formikProps,
      initialValues: DataFlattener.parse(loc.state)  
    }
  } else {
    formikPropsData = FormProperties.formikProps
  }

  function makeTranslatedTextsObject() {
    let saveButton = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.SAVE_BUTTON
    )
    let submitButton = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.SUBMIT_BUTTON
    )
    return {
      saveButton,
      submitButton
    }
  }

  return (
    <Card>
      <Box padding={2}>
        <CngAddForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          history={history}
          showNotification={showNotification}
          renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
            />
          )}
          formikProps={formikPropsData}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          create={{
            url: ArrivalCertApiUrls.POST,
            successRedirect: pathMap.ACI_HIGHWAY_INBOND_ARRIVAL_LIST_VIEW
          }}
          renderButtonSection={(loading) => (
            <ButtonSection
              history={history}
              loading={loading}
              onSetLoading={onSetLoading}
              saveButtonLabel={translatedTextsObject.saveButton}
              showNotification={showNotification}
              submitButtonLabel={translatedTextsObject.submitButton}
            />
          )}
        />
      </Box>
    </Card>
  )
}

export default withFormState(AddPage)

function ButtonSection(props) {
  const {
    history,
    loading,
    onSetLoading,
    saveButtonLabel,
    showNotification,
    submitButtonLabel
  } = props

  const { securedSendRequest } = useServices()
  const { getValues } = useFormContext()
  const { watch } = useFormContext();
  const ccnList = watch('ccnList')

  useEffect(() => {
    onSetLoading(loading)
  }, [loading])

  function handleSubmit() {
    onSetLoading(true)
    let values = { ...getValues() }
    values.ccnList = ccnList;
    securedSendRequest.execute(
      'POST',
      ArrivalCertApiUrls.SUBMIT,
      values,
      (response) => {
        var data = response.data
        var errorMessages = data.errorMessages
        if (response.status == 200 && data != null && errorMessages == null) {
          showNotification('success', 'Submitted successfully')
          history.push(pathMap.ACI_HIGHWAY_INBOND_ARRIVAL_LIST_VIEW)
        } else {
          showNotification('error', errorMessages)
        }
      },
      () => onSetLoading(false)
    )
  }

  return (
    <Grid container justify='space-between' spacing={3}>
      <Grid item xs='auto'>
        <CngButton color='secondary' disabled={loading} onClick={() => history.push(pathMap.ACI_HIGHWAY_INBOND_ARRIVAL_LIST_VIEW)} size='medium'>
          Discard
        </CngButton>
      </Grid>
      <Grid item xs='auto'>
        <Grid container spacing={2}>
          <Grid item xs='auto'>
            <CngButton color='primary' disabled={loading} type='submit' size='medium' shouldShowProgress={loading}>
              {saveButtonLabel}
            </CngButton>
          </Grid>
          <Grid item xs='auto'>
            <CngButton color='primary' disabled={loading} onClick={handleSubmit} size='medium'>
              {submitButtonLabel}
            </CngButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

