import AciHighwayCargoApiUrls from 'src/apiUrls/AciHighwayCargoApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React, { useRef, useState, useEffect } from 'react'
import { components, constants, DataFlattener, useTranslation } from 'cng-web-lib'
import { useHistory, useParams } from 'react-router-dom'
import pathMap from 'src/paths/pathMap'
import Namespace from 'src/constants/locale/Namespace'
import CargoKeys from 'src/constants/locale/key/AciHighwayCargo'
import ConfirmDialog from '../../common/ConfirmDialog'
import ChargeTypeDialog from '../../common/ChargeTypeDialog'
import axios from 'axios'
import { useFormContext } from 'react-hook-form'
import { FileForUserGetUserDetails, GetLoggedInUserPartyName } from 'src/common/FileForUserCommon'

const {
  button: { CngButton },
  form: { CngEditForm },
  table: { useFetchCodeMaintenanceLookup },
} = components

const { filter: { EQUAL } } = constants

function EditPage({ showNotification, setCCNValue, setManifestId }) {
  const { id } = useParams()
  const history = useHistory()
  const serverDataRef = useRef()
  const [loading, setLoading] = useState(false)
  const { translate } = useTranslation(Namespace.ACI_HIGHWAY_CARGO)
  const translatedTextsObject = makeTranslatedTextsObject()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  const [chargeTypes, setChargeTypes] = useState([])
  const [submitDialog, setSubmitDialog] = useState({ open: false, items: [] })

  const [isSuperParty, setSuperParty] = useState(false);

  useEffect(() => {
    Promise.all([
      // Charge type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_CHARGE_TYPE' }],
        undefined,
        'code'
      ),
      // Super party
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_SUPER_PARTY' }],
        undefined, 'code'
      )
    ]).then(
      ([billingChargeTypes, billingSuperParty]) => {
        compileChargeTypes(billingChargeTypes)

        Object.keys(billingSuperParty).forEach(e => {
          if (e == GetLoggedInUserPartyName()) {
            setSuperParty(true);
          }
        })
      }
    )
  }, [])

  function compileChargeTypes(billingChargeTypes) {
    var jsonArray = [];
    if (billingChargeTypes) {
      Object.entries(billingChargeTypes).forEach((e) => {
        var jsonObject = { code: e[0], desc: e[1] }
        jsonArray.push(jsonObject)
      })
    }
    setChargeTypes(jsonArray)
  }

  function makeTranslatedTextsObject() {
    let updateButton = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      CargoKeys.UPDATE_BUTTON
    )
    let submitButton = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      CargoKeys.SUBMIT_BUTTON
    )
    let submitSuccessMsg = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      CargoKeys.SUBMIT_SUCCESS_MSG
    )

    return {
      updateButton,
      submitButton,
      submitSuccessMsg
    }
  }

  function preSubmit() {
    const values = { ...serverDataRef.current, id }

    if (isSuperParty) {
      setSubmitDialog({ open: true, items: values })
    } else {
      values.chargeAs = 'REGULAR'
      serverDataRef.current = values
      handleSubmitCargo()
    }
  }

  function handleSubmitCargo() {
    setLoading(true)

    const instance = axios.create({
      withCredentials: false,
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userToken'))
      }
    })

    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      serverDataRef.current.fileForUserId = fileForUserDetails.fileForUserId;
      serverDataRef.current.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      serverDataRef.current.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    instance
      .post(
        AciHighwayCargoApiUrls.SUBMIT,
        DataFlattener.unflatten(serverDataRef.current)
      )
      .then((result) => {
        var returnData = result.data
        var errorMessages = returnData.errorMessages
        if (
          result.status == 200 &&
          returnData != null &&
          errorMessages == null
        ) {
          showNotification('success', translatedTextsObject.submitSuccessMsg)
          history.push(pathMap.ACI_HW_CARGO_LIST_VIEW)
        } else {
          showNotification('error', errorMessages)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => setLoading(false))
  }

  return (
    <>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngEditForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          showNotification={showNotification}
          renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
            />
          )}
          formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          fetch={{
            url: AciHighwayCargoApiUrls.GET,
            onPreSuccess: (serverData) => {
              serverData.isEditTemplate = serverData.templateFlag
              serverDataRef.current = serverData
              setCCNValue(serverData.ccn)
              setManifestId(serverData.manifestId)
            }
          }}
          update={{
            url: AciHighwayCargoApiUrls.PUT,
            onPostSubmitSuccess: (e) => {
              let fileForUserDetails = FileForUserGetUserDetails();
              if (fileForUserDetails != null && fileForUserDetails != undefined) {
                e.fileForUserId = fileForUserDetails.fileForUserId;
                e.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
                e.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
              }
              serverDataRef.current = e
            }
          }}
          id={id}
          renderButtonSection={() => (
            <ButtonSection
              history={history}
              loading={loading}
              onSubmitCargo={(data) => {
                serverDataRef.current = data
                preSubmit()
              }}
              translatedTextsObject={translatedTextsObject}
            />
          )}
        />
      </Grid>
    </Grid>

      <ChargeTypeDialog
        chargeTypes={chargeTypes}
        onClose={() => setSubmitDialog({ open: false, items: [] })}
        onSubmit={(data) => {
          serverDataRef.current = data
          handleSubmitCargo()
        }}
        open={submitDialog.open}
        items={submitDialog.items}
      />
    </>
  )
}

export default EditPage

function ButtonSection(props) {
  const { history, loading, onSubmitCargo, translatedTextsObject } = props
  const [confirmDialog, setConfirmDialog] = useState(false)
  const { formState: { isSubmitting }, getValues, trigger } = useFormContext()

  return (
    <>
      <Grid container justify='space-between'>
        <Grid item xs='auto'>
          <CngButton
            color='secondary'
            disabled={isSubmitting || loading}
            onClick={() => setConfirmDialog(true)}
          >
            Discard
          </CngButton>
        </Grid>
        <Grid item xs='auto'>
          <Grid container spacing={2}>
            <Grid item xs='auto'>
              <CngButton
                type='submit'
                color='primary'
                disabled={isSubmitting || loading}
              >
                {translatedTextsObject.updateButton}
              </CngButton>
            </Grid>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={isSubmitting || loading}
                shouldShowProgress={loading}
                onClick={async () => {
                  let result = await trigger()
                  if (result) {
                    onSubmitCargo(getValues())
                  }
                }}
              >
                {translatedTextsObject.submitButton}
              </CngButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog}
        closeDialog={() => setConfirmDialog(false)}
        confirmDialog={() => history.push(pathMap.ACI_HW_CARGO_LIST_VIEW)}
        content="All progress in this session will be lost and cant't be restored. Are you sure about this?"
        okMsg='Yes, discard'
        cancelMsg='Continue editing'
        title='Discard'
      />
    </>
  )
}
