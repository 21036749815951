import React from 'react'
import { components, constants, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import AciHighwayCargoKeys from 'src/constants/locale/key/AciHighwayCargo'
import FormProperties from './CargoCommodityFormProperties'
import CngSection from 'src/components/cngcomponents/CngSection'
import { Box, Divider, Grid, Typography } from '@material-ui/core'
import CngField from 'src/components/cngcomponents/CngField'
import TinyChip from 'src/components/aciacehighway/TinyChip'
import CngSubSection from 'src/components/cngcomponents/CngSubSection'

const {
  button: { CngButton },
  form: { CngForm },
  CngDialog
} = components

const { FormState } = constants

function CargoCommodityDialog(props) {
  const {
    cargoCommodity,
    isView,
    onAddCargoCommodity,
    onEditCargoCommodity,
    onClose,
    open,
    showNotification
  } = props

  const { initialValues, makeValidationSchema } = FormProperties.formikProps
  const { translate } = useTranslation(Namespace.ACI_HIGHWAY_CARGO)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let cargoCommodity = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CargoCommodity.TITLE
    )
    let productDesc = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CargoCommodity.PRODUCT_DESC
    )
    let quantity = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CargoCommodity.QUANTITY
    )
    let quantityUOM = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CargoCommodity.QUANTITY_U_O_M
    )
    let weight = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CargoCommodity.WEIGHT
    )
    let weightUom = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CargoCommodity.WEIGHT_UOM
    )
    let hsCode = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CargoCommodity.HS_CODE
    )
    let marksNumber = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CargoCommodity.MARKS_NUMBER
    )
    let hazardousMaterial = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.HazardousMaterial.TITLE
    )
    let undgCode = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.HazardousMaterial.UNDG_CODE
    )
    let undgDesc = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.HazardousMaterial.UNDG_DESC
    )
    let adminName = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.HazardousMaterial.ADMIN_NAME
    )
    let adminContact = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.HazardousMaterial.ADMIN_CONTACT
    )
    let saveButton = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.SAVE_BUTTON
    )
    let updateButton = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.UPDATE_BUTTON
    )

    return {
      cargoCommodity,
      saveButton,
      updateButton,
      productDesc,
      quantity,
      quantityUOM,
      weight,
      weightUom,
      hsCode,
      marksNumber,
      hazardousMaterial,
      undgCode,
      undgDesc,
      adminName,
      adminContact
    }
  }

  if (!open) {
    return null
  }

  let viewContent = cargoCommodity && (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngSection title='Classification &amp; weight'>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} md={3} />
                <Grid item xs={12} md={9}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <CngField label={translatedTextsObject.productDesc}>
                        {cargoCommodity.productDesc}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CngField label={`${translatedTextsObject.quantity} / ${translatedTextsObject.quantityUOM}`}>
                        <Typography component='div' variant='inherit'>
                          {cargoCommodity.quantity}
                          <TinyChip label={cargoCommodity.quantityUOM} variant='outlined' />
                        </Typography>
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CngField label={`${translatedTextsObject.weight} / ${translatedTextsObject.weightUom}`}>
                        <Typography component='div' variant='inherit'>
                          {cargoCommodity.weight}
                          <TinyChip label={cargoCommodity.weightUom} variant='outlined' />
                        </Typography>
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CngField label={translatedTextsObject.hsCode}>
                        {cargoCommodity.hsCode}
                      </CngField>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <CngSubSection title={translatedTextsObject.hazardousMaterial}>
                {cargoCommodity.hazardousMaterial.length > 0 ? (
                  <Grid container spacing={2}>
                    {cargoCommodity.hazardousMaterial.map((material, index) => (
                      <React.Fragment key={material._id || index}>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                              <CngField label={translatedTextsObject.undgCode}>
                                {material.undgCode}
                              </CngField>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <CngField label={translatedTextsObject.adminName}>
                                {material.adminName}
                              </CngField>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <CngField label={translatedTextsObject.adminContact}>
                                {material.adminContact}
                              </CngField>
                            </Grid>
                            <Grid item xs={12}>
                              <CngField label={translatedTextsObject.undgDesc}>
                                {material.undgDesc}
                              </CngField>
                            </Grid>
                          </Grid>
                        </Grid>
                        {cargoCommodity.hazardousMaterial.length !== index + 1 && (
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        )}
                      </React.Fragment>
                    ))}
                  </Grid>
                ) : (
                  <Typography variant='body2' color='textSecondary'>
                    No records to display
                  </Typography>
                )}
              </CngSubSection>
            </Grid>
          </Grid>
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.marksNumber}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} md={3} />
                <Grid item xs={12} md={9}>
                  <CngField label={translatedTextsObject.marksNumber}>
                    {cargoCommodity.marksNumber}
                  </CngField>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CngSection>
      </Grid>
    </Grid>
  )

  return (
    <CngDialog
      dialogContent={
        cargoCommodity && isView ? (
          viewContent
        ) : (
          <CngForm
            fieldLevel='form'
            formikProps={{
              initialValues: cargoCommodity || initialValues,
              makeValidationSchema: makeValidationSchema,
              onSubmit: (data) => {
                if (cargoCommodity) {
                  onEditCargoCommodity(data)
                } else {
                  onAddCargoCommodity(data)
                }

                onClose()
              }
            }}
            formState={FormState.COMPLETED}
            renderBodySection={(labelMap, shouldHideMap) => (
              <FormProperties.Fields
                showNotification={showNotification}
                shouldHideMap={shouldHideMap}
              />
            )}
            innerForm={true}
            renderButtonSection={() =>
              <Box display='flex' justifyContent='flex-end'>
                <CngButton color='primary' type='submit'>
                  {cargoCommodity ? translatedTextsObject.updateButton : translatedTextsObject.saveButton}
                </CngButton>
              </Box>
            }
          />
        )
      }
      dialogAction={
        isView ? <CngButton onClick={onClose}>OK</CngButton> : null
      }
      dialogTitle={translatedTextsObject.cargoCommodity}
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      fullWidth
      maxWidth='lg'
      onClose={onClose}
      open={open}
      shouldShowCloseButton
    />
  )
}

export default CargoCommodityDialog
