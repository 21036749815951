import AciHighwayCargoApiUrls from 'src/apiUrls/AciHighwayCargoApiUrls'
import FormProperties from './FormProperties'
import { Card, CardContent, Grid } from '@material-ui/core'
import React, { useState } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import pathMap from 'src/paths/pathMap'
import { useLocation } from "react-router-dom";
import { DataFlattener } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import CargoKeys from 'src/constants/locale/key/AciHighwayCargo'
import ConfirmDialog from '../../common/ConfirmDialog'
import axios from 'axios'
import { useFormContext } from 'react-hook-form'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'

const {
  button: { CngButton },
  form: { CngAddForm  }
} = components

function AddPage({ history, showNotification }) {
  const loc = useLocation()
  const [loading, setLoading] = useState(false)
  let formikPropsData;
  let  manifestId;
  if(loc.state !== undefined) {
    formikPropsData = {
          ...FormProperties.formikProps,
          initialValues: loc.state
    }
    manifestId=loc.state.manifestId;
  } else {
    formikPropsData = FormProperties.formikProps
  }

  const { translate } = useTranslation(Namespace.ACI_HIGHWAY_CARGO)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let saveButton = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      CargoKeys.SAVE_BUTTON
    )
    let submitButton = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      CargoKeys.SUBMIT_BUTTON
    )
    let submitSuccessMsg = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      CargoKeys.SUBMIT_SUCCESS_MSG
    )

    return {
      saveButton,
      submitButton,
      submitSuccessMsg
    }
  }

  function handleSubmitCargo(values) {
    setLoading(true)

    const instance = axios.create({
      withCredentials: false,
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userToken'))
      }
    })

    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      values.fileForUserId = fileForUserDetails.fileForUserId;
      values.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      values.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    instance
      .post(AciHighwayCargoApiUrls.SUBMIT, DataFlattener.unflatten(values))
      .then((result) => {
        var returnData = result.data
        var errorMessages = returnData.errorMessages
        if (
          result.status == 200 &&
          returnData != null &&
          errorMessages == null
        ) {
          showNotification('success', translatedTextsObject.submitSuccessMsg)
          history.push(pathMap.ACI_HW_CARGO_LIST_VIEW)
        } else {
          showNotification('error', errorMessages)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => setLoading(false))
  }

  return (
    <Card>
      <CardContent>
        <CngAddForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          history={history}
          showNotification={showNotification}
          renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
            />
          )}
          formikProps={formikPropsData}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          create={{
            url: AciHighwayCargoApiUrls.POST,
            successRedirect: (manifestId!==null && manifestId!==undefined) ? '/aci-highway/manifest/edit/'+manifestId: pathMap.ACI_HW_CARGO_LIST_VIEW
          }}
          renderButtonSection={() => (
            <ButtonSection
              history={history}
              loading={loading}
              onSubmitCargo={handleSubmitCargo}
              translatedTextsObject={translatedTextsObject}
            />
          )}
        />
      </CardContent>
    </Card>
  )
}

export default AddPage

function ButtonSection(props) {
  const { history, loading, onSubmitCargo, translatedTextsObject } = props

  const [confirmDialog, setConfirmDialog] = useState(false)
  const {
    formState: { isSubmitting },
    getValues
  } = useFormContext()

  return (
    <>
      <Grid container justify='space-between'>
        <Grid item xs='auto'>
          <CngButton
            color='secondary'
            disabled={isSubmitting || loading}
            onClick={() => setConfirmDialog(true)}
          >
            Discard
          </CngButton>
        </Grid>
        <Grid item xs='auto'>
          <Grid container spacing={2}>
            <Grid item xs='auto'>
              <CngButton
                type='submit'
                color='primary'
                disabled={isSubmitting || loading}
              >
                {translatedTextsObject.saveButton}
              </CngButton>
            </Grid>
            {/* <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={isSubmitting || loading}
                shouldShowProgress={loading}
                onClick={() => onSubmitCargo(getValues())}
              >
                {translatedTextsObject.submitButton}
              </CngButton>
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog}
        closeDialog={() => setConfirmDialog(false)}
        confirmDialog={() => history.push(pathMap.ACI_HW_CARGO_LIST_VIEW)}
        content="All progress in this session will be lost and cant't be restored. Are you sure about this?"
        okMsg='Yes, discard'
        cancelMsg='Continue editing'
        title='Discard'
      />
    </>
  )
}
