import { Box, Card } from '@material-ui/core'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import { components, useTranslation } from 'cng-web-lib'
import { useParams, useLocation } from 'react-router-dom'
import ArrivalCertKeys from 'src/constants/locale/key/ArrivalCert'
import ArrivalCertEditPage from './ArrivalCertEditPage'
import ArrivalCertResponsePage from '../response/ArrivalCertResponse'
import withFormState from '../../../components/aciacehighway/hocs/withFormState'

const { CngTabs } = components

function EditPage({ history, onSetLoading, showNotification }) {
  const { id } = useParams()
  const loc = useLocation()
  const { translate } = useTranslation([Namespace.ARRIVAL_CERT])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let inbondArrival = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.TITLE
    )

    let response = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.RESPONSE_TITLE
    )
    return {
      inbondArrival,
      response
    }
  }

  return (
    <Card>
      <Box padding={2}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.inbondArrival,
              tabContent: (
                <ArrivalCertEditPage
                  showNotification={showNotification}
                  onSetLoading={onSetLoading}
                  id={id}
                  history={history}
                />
              )
            },
            {
              tabName: translatedTextsObject.response,
              tabContent: (
                <ArrivalCertResponsePage
                  showNotification={showNotification}
                  id={id}
                />
              )
            }
          ]}
        />
      </Box>
    </Card>
  )
}

export default withFormState(EditPage)
