import React, { useState, useContext } from 'react'
import { ACIHighwayTranslationContext } from '../../contexts/ACIHighwayTranslationContext'
import { Grid } from '@material-ui/core'
import { useFormContext, useWatch } from "react-hook-form";
import CngSection from 'src/components/cngcomponents/CngSection'
import CargoCommodityTable from '../CargoCommodityTable'
import CargoCommodityDialog from '../CargoCommodityDialog'

function CargoCommoditySection({ showNotification }) {
    const { getValues, setValue } = useFormContext();
    const { getTranslatedText } = useContext(ACIHighwayTranslationContext)
    const cargoCommodity = useWatch({ name: 'cargoCommodity' })

    const [cargoCommodityDialog, setCargoCommodityDialog] = useState({
        open: false,
        cargoCommodity: null,
        isView: false
    })

    function handleAddCargoCommodity(data) {
        const values = { ...data }
        const newCargoCommodity = [...cargoCommodity, values]

        setValue('cargoCommodity', newCargoCommodity, { shouldValidate: true })
    }

    function handleDeleteCargoCommodity(data) {
        const newCargoCommodity = [...getValues("cargoCommodity")].filter((cargo) =>
            cargo._id ? cargo._id !== data._id : cargo.id !== data.id)

        setValue('cargoCommodity', newCargoCommodity, { shouldValidate: true })
    }

    function handleEditCargoCommodity(data) {
        const values = { ...data }

        const newCargoCommodity = [...cargoCommodity]
        const index = newCargoCommodity.findIndex((cargo) =>
            data.id ? cargo.id === data.id : cargo._id === data._id
        )

        newCargoCommodity[index] = values

        setValue('cargoCommodity', newCargoCommodity, { shouldValidate: true })
    }

    return (
        <Grid item xs={12}>
            <CngSection title={getTranslatedText('ACI_HIGHWAY_CARGO', 'cargoCommodity')}
                helperText={getTranslatedText('ACI_HIGHWAY_CARGO', 'commoditySectionHelper')} alignItems='flex-end'>
                <CargoCommodityTable
                    cargoCommodity={cargoCommodity}
                    onAddCargoCommodity={() =>
                        setCargoCommodityDialog({ open: true, cargoCommodity: null, isView: false })
                    }
                    onEditCargoCommodity={(cargoCommodity) =>
                        setCargoCommodityDialog({ open: true, cargoCommodity, isView: false })
                    }
                    onDeleteCargoCommodity={handleDeleteCargoCommodity}
                    onViewCargoCommodity={(cargoCommodity) =>
                        setCargoCommodityDialog({ open: true, cargoCommodity, isView: true })
                    }
                />
                <CargoCommodityDialog
                    cargoCommodity={cargoCommodityDialog.cargoCommodity}
                    isView={cargoCommodityDialog.isView}
                    onAddCargoCommodity={handleAddCargoCommodity}
                    onEditCargoCommodity={handleEditCargoCommodity}
                    open={cargoCommodityDialog.open}
                    onClose={() =>
                        setCargoCommodityDialog({ open: false, cargoCommodity: null, isView: false })
                    }
                    showNotification={showNotification}
                />
            </CngSection>
        </Grid>
    )
}

export default CargoCommoditySection