import React, { useEffect, useState, useContext, useMemo } from 'react'
import { components, useServices, constants } from 'cng-web-lib'
import { useFormContext, useWatch } from "react-hook-form";
import { Grid } from '@material-ui/core'
import { fetchUser } from "src/views/userprofile/UserProfileService.js";
import { ACIHighwayTranslationContext } from '../contexts/ACIHighwayTranslationContext'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import makeValidationSchema from './MakeValidationSchema'
import CargoPartiesSection from './Sections/CargoPartiesSection'
import CargoDetailsSection from './Sections/CargoDetailsSection'
import CargoCommoditySection from './Sections/CargoCommoditySection'
import CngSection from 'src/components/cngcomponents/CngSection'
import moment from 'moment-timezone'

const {
  form: {
    field: {
      CngCodeMasterAutocompleteField
    },
  },
  CngGridItem,
} = components

const { filter: { EQUAL } } = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  cargoType: "REGULAR",
  consolidated: "N",
  carrierCode: "",
  ccn: "",
  firstExpectedPort: "",
  arrivalDateAndTime: "",
  arrivalDate: moment().tz("Canada/Eastern").format('YYYY-MM-DD'),
  arrivalTime: "23:59",
  arrivalDateAndTimeZone: "",
  paperRelease: "",
  inbondDestinationOfficeCode: "",
  warehouseCode: "",
  polCity: "",
  polCountryCode: "",
  polStateCode: "",
  poaCity: "",
  poaCountryCode: "",
  poaStateCode: "",
  adminName: "",
  adminContact: "",
  specialInstructions: "",
  portOfExit: "",
  acquittalNo: "",
  printDate: "",
  printRemarks: "",
  messageFunction: "00",
  amendmentCode: "",
  status: "",
  tripNo: "",
  manifestId: "",
  templateFlag: false,
  templateName: "",
  version: "",
  cargoLoadedIn: "",
  cargoParties: [],
  cargoCommodity: [],
  cargoLoadedInTruck: "",
  cargoLoadedInTrailer: "",
  entryNo: "",
  // hazardousMaterial: [],
  partyId: "",
  modeOfSubmission: "",
  oriCreatedBy: "",
  oriUpdatedBy: "",
  oriSubmittedBy: "",
  apiAutoSubmit: null,
  isEditTemplate: false,
  accId: "",
  userId: "",
  messageId: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap, manifestCarrierCode }) {
  const { securedSendRequest } = useServices()
  const { getValues, setValue } = useFormContext();
  const { getTranslatedText } = useContext(ACIHighwayTranslationContext)

  const cargoType = useWatch({ name: "cargoType" });
  const messageFunction = useWatch({ name: "messageFunction" });

  const {
    table: { useFetchCodeMaintenanceLookup },
  } = components

  const headerId = getValues('headerId');
  const [cargoTypes, setCargoTypes] = useState([])
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()

  useEffect(() => {
    Promise.all([
      // Charge type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACIHW_CARGO_TYPE' }],
        undefined,
        'code'
      )
    ]).then(
      (cargoTypesResponse) => {
        compileCargoTypes(cargoTypesResponse)
      }
    )
  }, [])

  function compileCargoTypes(cargoTypesResponse) {
    var jsonArray = [];
    if (cargoTypesResponse) {
      Object.entries(cargoTypesResponse[0]).forEach(([key, value]) => {
        if (key === "OSC" && !headerId) {
          if (cargoType === 'OSC') {
            var jsonObject = { text: value, value: key };
            jsonArray.push(jsonObject);
          }
          // Skip the current iteration when the key is "OSC" and headerId is false
          return;
        } else {
          var jsonObject = { text: value, value: key };
          jsonArray.push(jsonObject);
        }
      });
    }
    setCargoTypes(jsonArray)
  }

  const [user, setUser] = useState([]);
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    fetchUser(setUser, securedSendRequest, fileForUserDetails);
  }, []);

  useWatch("fileForUserId");
  useWatch("fileForUserLoginId")
  useWatch("fileForUserPartyId");
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  const memoedCargoDetailsSection = useMemo(() => (
    <CargoDetailsSection disabled={disabled} shouldHideMap={shouldHideMap}
      user={user} cargoTypes={cargoTypes} showNotification={showNotification} manifestCarrierCode={manifestCarrierCode}/>
  ), [user, cargoTypes])

  const memoedCargoPartiesSection = useMemo(() => (
    <CargoPartiesSection />
  ), [])

  const memoedCargoCommoditySection = useMemo(() => (
    <CargoCommoditySection showNotification={showNotification} />
  ), [])

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CngSection helperText={getTranslatedText('ACI_HIGHWAY_CARGO', 'cargoSectionHelper')} title={getTranslatedText('ACI_HIGHWAY_CARGO', 'aciHighway')}>
            {memoedCargoDetailsSection}
          </CngSection>
        </Grid>
        {(cargoType !== "OSC") && memoedCargoPartiesSection}
        {(cargoType !== "OSC") && memoedCargoCommoditySection}
        <Grid item xs={12}>
          <CngSection title={getTranslatedText('ACI_HIGHWAY_CARGO', 'messageFunction')}>
            <Grid container spacing={1}>
              <CngGridItem xs={12} shouldHide={shouldHideMap.messageFunction}>
                <CngCodeMasterAutocompleteField
                  required
                  name="messageFunction"
                  label={getTranslatedText('ACI_HIGHWAY_CARGO', 'messageFunction')}
                  disabled={disabled}
                  codeType='ACIHW_MESSAGE_FUNCTION'
                  size='small'
                />
              </CngGridItem>
              {(messageFunction === 'CO') && (
                <CngGridItem xs={12}>
                  <CngCodeMasterAutocompleteField
                    required
                    name='amendmentCode'
                    label={getTranslatedText('ACI_HIGHWAY_CARGO', 'amendmentCode')}
                    disabled={disabled}
                    codeType='ACIHW_CARGO_AMENDMENT_CODE'
                    size='small'
                  />
                </CngGridItem>
              )}
            </Grid>
          </CngSection>
        </Grid>
      </Grid>
    </>
  )
}

function toClientDataFormat(serverData) {
  // let localData = DataFlattener.parse(serverData);
  console.log("toClientDataFormat", JSON.stringify(serverData));
  let localData = serverData;
  localData.cargoCommodity.forEach((datum) => {
    datum.quantity = Number(datum.quantity).toString();
    datum.weight = Number(datum.weight).toString();
  });

  localData.arrivalTime = moment(localData.arrivalDateAndTime).format('HH:mm');
  localData.arrivalDate = moment(localData.arrivalDateAndTime).format('YYYY-MM-DD');

  if (localData.cargoLoadedInTrailer) {
    localData.cargoLoadedIn = ("L" + localData.cargoLoadedInTrailer)
  } else if (localData.cargoLoadedInTruck) {
    localData.cargoLoadedIn = ("V" + localData.cargoLoadedInTruck)
  }

  //pending confirmation, will be submitting whole number if no decimal
  // localData.cargoCommodity.forEach((datum) => {
  // if(datum.weight % 1 === 0){ //if weight is not decimal
  //   datum.weight = parseFloat(datum.weight).toFixed(1); //then put .0 behind
  // }
  // });
  return localData;
}

function toServerDataFormat(localData) {
  // return DataFlattener.unflatten(localData);
  localData.arrivalDateAndTime = localData.arrivalDate + ' ' + localData.arrivalTime
  return localData;
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat,
})

export default FormProperties
