import React, { useContext } from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import AciAirKeys from '../../../../constants/locale/key/AciAir'
import { LookupsContext } from '../../../../contexts/LookupsContext'
import StatusBar from '../../../../components/aciacehighway/StatusBar'
import CngField from '../../../../components/cngcomponents/CngField'
import CngSection from '../../../../components/cngcomponents/CngSection'
import TinyChip from '../../../../components/aciacehighway/TinyChip'
import { Box, Card, Divider, Grid, Typography, useTheme } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import _ from 'lodash'
import ViewLogsHistory from '../../aciaircommon/ViewLogsHistory'

function ReportViewContent(props) {
  const { data, reportId, showNotification } = props

  const { getLookupValue } = useContext(LookupsContext)
  const { translate } = useTranslation(Namespace.ACI_AIR)
  const translatedTextsObject = makeTranslatedTextsObject()
  const theme = useTheme()

  function makeTranslatedTextsObject() {
    const schedule = translate(Namespace.ACI_AIR, AciAirKeys.SCHEDULE)
    const customsProc = translate(Namespace.ACI_AIR, AciAirKeys.CUSTOMS_PROC)
    const convRefNo = translate(Namespace.ACI_AIR, AciAirKeys.CONV_REF_NO)
    const flightNo = translate(Namespace.ACI_AIR, AciAirKeys.VOYAGE_NO)
    const carrierCode = translate(Namespace.ACI_AIR, AciAirKeys.CARRIER_CODE)
    const ccn = translate(Namespace.ACI_AIR, AciAirKeys.CCN)
    const portOfLoading = translate(
      Namespace.ACI_AIR,
      AciAirKeys.PORT_OF_LOADING
    )
    const customsOff = translate(Namespace.ACI_AIR, AciAirKeys.CUSTOMS_OFF)
    const warehouseDischarge = translate(
      Namespace.ACI_AIR,
      AciAirKeys.WAREHOUSE_DISCHARGE
    )
    const rampTransferInd = translate(
      Namespace.ACI_AIR,
      AciAirKeys.RAMP_TRANSFER_IND
    )
    const specialIns = translate(Namespace.ACI_AIR, AciAirKeys.SPECIAL_INS)
    const scheduleDateAndTime = translate(
      Namespace.ACI_AIR,
      AciAirKeys.SCHEDULE_DATE_TIME
    )
    const customsOfficeDischarge = translate(
      Namespace.ACI_AIR,
      AciAirKeys.CUSTOMS_OFF_DISCHARGE
    )
    const customsOfficeDischargeLoc = translate(
      Namespace.ACI_AIR,
      AciAirKeys.CUSTOMS_OFF_DISCHARGE_LOC
    )
    const aciAirRouteList = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirRouteList.TITLE
    )
    const routeCarrierCode = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirRouteList.CARRIER_CODE
    )
    const locationCode = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirRouteList.LOCATION_CODE
    )
    const aciAirManifestQtyList = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirManifestQtyList.TITLE
    )
    const quantity = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirManifestQtyList.QUANTITY
    )
    const uom = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirManifestQtyList.UOM
    )
    const aciAirEquipmentList = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirEquipmentList.TITLE
    )
    const containerid = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirEquipmentList.CONTAINERID
    )
    const aciAirStatusNotifyList = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirStatusNotifyList.TITLE
    )
    const statusType = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirStatusNotifyList.STATUS_TYPE
    )
    const email = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirStatusNotifyList.EMAIL
    )
    const phoneNumber = translate(
      Namespace.ACI_AIR,
      AciAirKeys.AciAirStatusNotifyList.PHONE_NUMBER
    )

    return {
      carrierCode,
      ccn,
      customsProc,
      rampTransferInd,
      specialIns,
      aciAirManifestQtyList,
      quantity,
      uom,
      aciAirEquipmentList,
      containerid,
      schedule,
      flightNo,
      convRefNo,
      customsOfficeDischarge,
      customsOfficeDischargeLoc,
      portOfLoading,
      customsOff,
      warehouseDischarge,
      scheduleDateAndTime,
      aciAirRouteList,
      routeCarrierCode,
      locationCode,
      aciAirStatusNotifyList,
      email,
      phoneNumber,
      statusType
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <StatusBar status={data.hdrStatus} />
      </Grid>
      <Grid item xs={12}>
        <Card variant='outlined'>
          <Box padding={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box mb={2}>
                  <Typography variant='h5' style={{ fontWeight: 600 }}>
                    General Information
                  </Typography>
                </Box>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={4}>
                    <CngField label={translatedTextsObject.carrierCode}>
                      {data.carrierCode}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <CngField label={translatedTextsObject.ccn}>
                      {data.ccn}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <CngField label={translatedTextsObject.customsProc}>
                      {data.customsProc && (
                        <Typography component='div' variant='inherit'>
                          {getLookupValue('customsProc', data.customsProc)}
                          <TinyChip label={data.customsProc} variant='outlined' />
                        </Typography>
                      )}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <CngField label={translatedTextsObject.rampTransferInd}>
                      {data.rampTransferInd === '1' ? 'Yes' : 'No'}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <CngField label={translatedTextsObject.specialIns}>
                      {data.specialIns}
                    </CngField>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <CngSection title={translatedTextsObject.aciAirManifestQtyList}>
                  <Grid container spacing={2}>
                    {data.aciAirManifestQtyList.map((manifestQuantity) => (
                      <Grid key={manifestQuantity.id} item xs={12} sm={6} md={3}>
                        <CngField
                          label={[
                            translatedTextsObject.quantity,
                            translatedTextsObject.uom
                          ].join(' & ')}
                        >
                          <Typography component='div'>
                            {manifestQuantity.quantity}&nbsp;-&nbsp;
                            <Typography
                              component='div'
                              variant='inherit'
                              style={{ display: 'inline-block' }}
                            >
                              {getLookupValue('uomType', manifestQuantity.uom)}
                              <TinyChip label={manifestQuantity.uom} variant='outlined' />
                            </Typography>
                          </Typography>
                        </CngField>
                      </Grid>
                    ))}
                  </Grid>
                </CngSection>
              </Grid>
              <Grid item xs={12}>
                <CngSection title={translatedTextsObject.aciAirEquipmentList}>
                  <Grid container spacing={2}>
                    {data.aciAirEquipmentList.map((equipment) => (
                      <Grid key={equipment.id} item xs={12} sm={6} md={3}>
                        <CngField label={translatedTextsObject.containerid}>
                          {equipment.containerid}
                        </CngField>
                      </Grid>
                    ))}
                  </Grid>
                </CngSection>
              </Grid>
              <Grid item xs={12}>
                <CngSection
                  action={
                    <Box display='flex' alignItems='center' style={{ gap: 8 }}>
                      <FontAwesomeIcon
                        color={
                          data.scheduledSubmissionFlag
                            ? theme.palette.success.main
                            : theme.palette.error.main
                        }
                        icon={['fal', data.scheduledSubmissionFlag ? 'circle-check' : 'ban']}
                      />
                      <Typography
                        color='textSecondary'
                        variant='body2'
                        style={{ fontWeight: 600 }}
                      >
                        Schedule Delivery
                      </Typography>
                    </Box>
                  }
                  title={translatedTextsObject.schedule}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                      <CngField label={translatedTextsObject.flightNo}>
                        {data.flightNo}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CngField label={translatedTextsObject.convRefNo}>
                        {data.convRefNo}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CngField label={translatedTextsObject.customsOfficeDischarge}>
                        {data.customsOfficeDischarge && (
                          <Typography component='div' variant='inherit'>
                            {getLookupValue('customsOffice', data.customsOfficeDischarge)}
                            <TinyChip label={data.customsOfficeDischarge} variant='outlined' />
                          </Typography>
                        )}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CngField label={translatedTextsObject.customsOfficeDischargeLoc}>
                        {data.customsOfficeDischargeLoc && (
                          <Typography component='div' variant='inherit'>
                            {getLookupValue('sublocation', data.customsOfficeDischargeLoc)}
                            <TinyChip label={data.customsOfficeDischargeLoc} variant='outlined' />
                          </Typography>
                        )}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CngField label={translatedTextsObject.portOfLoading}>
                        <Typography component='div' variant='inherit'>
                          {getLookupValue('airport', data.portOfLoading)}
                          <TinyChip label={data.portOfLoading} variant='outlined' />
                        </Typography>
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CngField label={translatedTextsObject.customsOff}>
                        {data.customsOff && (
                          <Typography component='div' variant='inherit'>
                            {getLookupValue('customsOffice', data.customsOff)}
                            <TinyChip label={data.customsOff} variant='outlined' />
                          </Typography>
                        )}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CngField label={translatedTextsObject.warehouseDischarge}>
                        {data.warehouseDischarge && (
                          <Typography component='div' variant='inherit'>
                            {getLookupValue('sublocation', data.warehouseDischarge)}
                            <TinyChip label={data.warehouseDischarge} variant='outlined' />
                          </Typography>
                        )}
                      </CngField>
                    </Grid>
                    {data.scheduledSubmissionFlag &&
                      data.scheduleDateAndTime && (
                        <Grid item xs={12} sm={6} md={3}>
                          <CngField label={translatedTextsObject.scheduleDateAndTime}>
                            {moment(data.scheduleDateAndTime).format('DD/MM/YYYY HH:mm:ss')}
                          </CngField>
                        </Grid>
                      )}
                  </Grid>
                </CngSection>
              </Grid>
              <Grid item xs={12}>
                <CngSection title={translatedTextsObject.aciAirRouteList}>
                  <Grid container spacing={2}>
                    {data.aciAirRouteList.map((route) => (
                      <Grid key={route.id} item xs={12} sm={6} md={3}>
                        <CngField
                          label={[
                            translatedTextsObject.routeCarrierCode,
                            translatedTextsObject.locationCode
                          ].join(' & ')}
                        >
                          <Typography component='div'>
                            {getLookupValue('carrierCode', route.carrierCode)}&nbsp;-&nbsp;
                            <Typography
                              component='div'
                              variant='inherit'
                              style={{ display: 'inline-block' }}
                            >
                              {getLookupValue('airport', route.locationCode)}
                              <TinyChip label={route.locationCode} variant='outlined' />
                            </Typography>
                          </Typography>
                        </CngField>
                      </Grid>
                    ))}
                  </Grid>
                </CngSection>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <CngSection title={translatedTextsObject.aciAirStatusNotifyList}>
          {!_.isEmpty(data.aciAirStatusNotifyList) ? (
            <Grid container spacing={2}>
              {data.aciAirStatusNotifyList.map((statusNotification, index) => (
                <React.Fragment key={statusNotification.id}>
                  <Grid container spacing={2} item xs={12}>
                    <Grid item xs={12} md={4}>
                      <CngField label={translatedTextsObject.email}>
                        {statusNotification.email}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <CngField label={translatedTextsObject.phoneNumber}>
                        {statusNotification.phoneNumber}
                      </CngField>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <CngField label={translatedTextsObject.statusType}>
                        {getLookupValue('statusType', statusNotification.statusType)}
                      </CngField>
                    </Grid>
                  </Grid>
                  {data.aciAirStatusNotifyList.length !== index + 1 && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          ) : (
            <Typography color='textSecondary'>
              No records to display
            </Typography>
          )}
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <ViewLogsHistory
          ccnValue={data.ccn}
          headerId={reportId}
          showNotification={showNotification}
        />
      </Grid>
    </Grid>
  )
}

export default ReportViewContent
