import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import { constants } from 'cng-web-lib'
import moment from 'moment'
import AciHighwayCargoKeys from 'src/constants/locale/key/AciHighwayCargo'


const { locale: { key: { CommonValidationMessageKeys } } } = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )
  let maxLength4= translate(
   Namespace.ACI_HIGHWAY_CARGO,
   AciHighwayCargoKeys.Validations.MAX_LENGTH_4
  )
  let minLength4= translate(
    Namespace.ACI_HIGHWAY_CARGO,
    AciHighwayCargoKeys.Validations.MIN_LENGTH_4
   )
   let carrierCodeRegx= translate(
    Namespace.ACI_HIGHWAY_CARGO,
    AciHighwayCargoKeys.Validations.CARRIER_CODE_REGX
   )
   let maxLength25= translate(
    Namespace.ACI_HIGHWAY_CARGO,
    AciHighwayCargoKeys.Validations.MAX_LENGTH_25
   )
   let minLength5= translate(
    Namespace.ACI_HIGHWAY_CARGO,
    AciHighwayCargoKeys.Validations.MIN_LENGTH_5
   )
   let alphaNumericRegx= translate(
    Namespace.ACI_HIGHWAY_CARGO,
    AciHighwayCargoKeys.Validations.ALPHA_NUMERIC_REGX
   )
   let carrierCodePrefix= translate(
    Namespace.ACI_HIGHWAY_CARGO,
    AciHighwayCargoKeys.Validations.CARRIER_CODE_PREFIX
   )
   let arrivalDateTimeRegx= translate(
    Namespace.ACI_HIGHWAY_CARGO,
    AciHighwayCargoKeys.Validations.ARRIVAL_DATE_REGX
   )
   let printDateRegx= translate(
    Namespace.ACI_HIGHWAY_CARGO,
    AciHighwayCargoKeys.Validations.PRINT_DATE_REGX
   )
   let maxLength30= translate(
    Namespace.ACI_HIGHWAY_CARGO,
    AciHighwayCargoKeys.Validations.MAX_LENGTH_30
   )
   let aplhaNumericWithSpace= translate(
    Namespace.ACI_HIGHWAY_CARGO,
    AciHighwayCargoKeys.Validations.ALPHA_NUMERIC_WITH_SPACE
   )
   let maxLength60= translate(
    Namespace.ACI_HIGHWAY_CARGO,
    AciHighwayCargoKeys.Validations.MAX_LENGTH_60
   )
   let maxLength35= translate(
    Namespace.ACI_HIGHWAY_CARGO,
    AciHighwayCargoKeys.Validations.MAX_LENGTH_35
   )
   let maxLength300= translate(
    Namespace.ACI_HIGHWAY_CARGO,
    AciHighwayCargoKeys.Validations.MAX_LENGTH_300
   )
   let maxLength200= translate(
    Namespace.ACI_HIGHWAY_CARGO,
    AciHighwayCargoKeys.Validations.MAX_LENGTH_200
  )
  let maxLength15 = translate(
    Namespace.ACI_HIGHWAY_CARGO,
    AciHighwayCargoKeys.Validations.MAX_LENGTH_15
  )
  let maxLength256 = translate(
    Namespace.ACI_HIGHWAY_CARGO,
    AciHighwayCargoKeys.Validations.MAX_LENGTH_256
   )
   let alphaNumericWithSplChar= translate(
    Namespace.ACI_HIGHWAY_CARGO,
    AciHighwayCargoKeys.Validations.ALPHA_NUMERIC_WITH_SPLCHARS
   )
   let msgFunForAC= translate(
    Namespace.ACI_HIGHWAY_CARGO,
    AciHighwayCargoKeys.Validations.MSGFUN_FOR_AC
   )
   let msgFunForDR= translate(
    Namespace.ACI_HIGHWAY_CARGO,
    AciHighwayCargoKeys.Validations.MSGFUN_FOR_DR
   )
   let msgFunForCL= translate(
    Namespace.ACI_HIGHWAY_CARGO,
    AciHighwayCargoKeys.Validations.MSGFUN_FOR_CL
   )
   let msgFunForMR= translate(
    Namespace.ACI_HIGHWAY_CARGO,
    AciHighwayCargoKeys.Validations.MSGFUN_FOR_MR
   )
   let msgFunForRJ= translate(
    Namespace.ACI_HIGHWAY_CARGO,
    AciHighwayCargoKeys.Validations.MSGFUN_FOR_RJ
   )
   let msgFunForCR= translate(
    Namespace.ACI_HIGHWAY_CARGO,
    AciHighwayCargoKeys.Validations.MSGFUN_FOR_CR
   )
   let emailValid=translate(
    Namespace.ACI_HIGHWAY_CARGO,
    AciHighwayCargoKeys.Validations.EMAIL_VALID
  )

  const regexAlphaNumericSpecChar = "^[a-zA-Z0-9!\"\',-./:;?=()_@./#&+\-,\\s]*$"

  return Yup.object({
    cargoType: Yup.string().required(requiredMessage).nullable(),
    consolidated: Yup.string().required(requiredMessage).nullable(),
    carrierCode: Yup.string().when('templateFlag', {
      is: (value)=>value === false,
      then: Yup.string().max(4, maxLength4).min(4, minLength4).required(requiredMessage).matches("^[a-zA-Z0-9\-]*$", carrierCodeRegx)}).nullable(),
    ccn: Yup.string().when('templateFlag', {
      is: (value)=>value === false,
      then: Yup.string().trim().max(25, maxLength25).min(5, minLength5).matches(/^[a-zA-Z0-9\-]+$/, alphaNumericRegx)
    .test("ccn",carrierCodePrefix,function(){
      let carrierCode = this.parent["carrierCode"];
      let ccn = this.parent["ccn"];
    if(ccn && carrierCode) {
      if(ccn.startsWith(carrierCode))
       return true;
      else
       return false;
    }
    }).required(requiredMessage)}).nullable(),
    firstExpectedPort: Yup.string().when('cargoType',{
      is: (value)=>value!=="OSC",
      then: Yup.string().required(requiredMessage),
      otherwise: Yup.string()
    }).nullable(),
    arrivalDate: Yup.string().when('cargoType',{
      is: (value)=>value!=="OSC",
      then: Yup.string().required(requiredMessage).test(
      'arrivalDate',
      arrivalDateTimeRegx,
      value =>  {
        return moment(value, 'YYYY-MM-DD').isValid()
      },
    ), otherwise: Yup.string()}).nullable(),
    arrivalTime: Yup.string().when('cargoType',{
      is: (value)=>value!=="OSC",
      then: Yup.string().required(requiredMessage).test(
      'arrivalTime',
      arrivalDateTimeRegx,
      value =>  {
        return moment(value, 'HH:mm').isValid()
      },
    ), otherwise: Yup.string()}).nullable(),
    arrivalDateAndTimeZone:Yup.string().nullable(),
    paperRelease: Yup.string().nullable(),
    inbondDestinationOfficeCode: Yup.string().when('cargoType',{
      is: (value)=>value==="IN-BOND",
      then: Yup.string().required(requiredMessage),
      otherwise: Yup.string()
    }).nullable(),
    warehouseCode: Yup.string().when('cargoType',{
      is: (value)=>value==="IN-BOND",
      then: Yup.string().required(requiredMessage),
      otherwise: Yup.string()
    }).nullable(),
    polCity: Yup.string().when('cargoType',{
      is: (value)=>value!=="OSC",
      then: Yup.string().required(requiredMessage).matches(regexAlphaNumericSpecChar, aplhaNumericWithSpace).max(30, maxLength30), 
      otherwise: Yup.string()
    }).nullable(),
    polCountryCode: Yup.string().when('cargoType',{
      is: (value)=>value!=="OSC",
      then: Yup.string().required(requiredMessage),
      otherwise: Yup.string()
    }).nullable(),
    polStateCode: Yup.string().when('polCountryCode',{
      is: (value)=>value=="US",
      then: Yup.string().required(requiredMessage), 
      otherwise: Yup.string()
    }).nullable(),
    poaCity: Yup.string().matches(/^[a-zA-Z0-9- ]+$/, aplhaNumericWithSpace).max(30,maxLength30).nullable(),
    poaCountryCode: Yup.string().nullable(),
    poaStateCode: Yup.string().nullable(),
    adminName: Yup.string().matches(/^[a-zA-Z0-9- ]+$/, aplhaNumericWithSpace).max(60,maxLength60).nullable(),
    adminContact: Yup.string().matches("^[a-zA-Z0-9 -]*$", alphaNumericWithSplChar).max(35,maxLength35).nullable(),
    specialInstructions: Yup.string().matches(/^[a-zA-Z0-9- ]+$/, aplhaNumericWithSpace).max(300,maxLength300).nullable(),
    portOfExit: Yup.string().nullable(),
    acquittalNo: Yup.string().matches(/^[a-zA-Z0-9-]+$/, alphaNumericRegx).max(25,maxLength25).nullable(),
    printDate: Yup.string().nullable(),
    printRemarks: Yup.string().matches(/^[a-zA-Z0-9- \n]+$/, aplhaNumericWithSpace).max(200,maxLength200).nullable(),
    messageFunction: Yup.string().when('status',{
      is: (value)=>value === "1000" || value ==="1006",
      then: Yup.string().test('messageFunction',msgFunForAC,function(value){
        if(value == "00") {
          return false;
        } else {
          return true;
        }
      }),
      otherwise: Yup.string().when('status',{
        is: (value) => value === "1005",
        then: Yup.string().test('messageFunction',msgFunForDR,function(value){
          if(value != "00"){
            return false;
          } else {
            return true;
          } 
        }),
        otherwise: Yup.string().when('status',{
          is: (value) => value === "1003",
          then: Yup.string().test('messageFunction',msgFunForCL,function(value){
            if(value != "00"){
              return false;
            } else {
              return true;
            } 
          }),
          otherwise: Yup.string().when('status',{
            is: (value) => value === "1007",
            then: Yup.string().test('messageFunction',msgFunForMR,function(value){
              if(value === "00" ){
                return false;
              } else {
                return true;
              } 
            })
          }),
          otherwise: Yup.string().when('status',{
            is: (value) => value === "1001",
            then: Yup.string().test('messageFunction',msgFunForRJ,function(value){
              if(value !== "04" ){
                return false;
              } else {
                return true;
              } 
            })
          }),
          otherwise: Yup.string().when('status',{
            is: (value) => value === "1004",
            then: Yup.string().test('messageFunction',msgFunForCR,function(value){
              if(value === "00" || value==="CO" ){
                return false;
              } else {
                return true;
              } 
            })
          }),
        })
      })
    }).nullable().required(requiredMessage),
    status: "",
    tripNo: "",
    templateName: Yup.string().when('templateFlag', {
      is: true,
      then: Yup.string().trim().required(requiredMessage).matches(/^[a-zA-Z0-9-., ]+$/, "Cannot contain special characters like ),(,@ etc."),
    }).nullable(),

    cargoParties: Yup.array(
      Yup.object({
        saveFlag: Yup.boolean().nullable(),
        partyIdentification: Yup.string().when('saveFlag', {
          is: true,
          then: Yup.string().trim().required(requiredMessage).matches(regexAlphaNumericSpecChar, aplhaNumericWithSpace),
        }).nullable(),
        partyType: Yup.string().required(requiredMessage).nullable(),
        businessNo: Yup.string().nullable().max(15, maxLength15),
        partyName: Yup.string().required(requiredMessage).matches(regexAlphaNumericSpecChar, aplhaNumericWithSpace).max(60, maxLength60).nullable(),

        address: Yup.string().when('partyType', {
          is: (value) => value !== "CB",
          then: Yup.string().required(requiredMessage).matches(regexAlphaNumericSpecChar, aplhaNumericWithSpace).max(200, maxLength200).nullable(),
          otherwise: Yup.string()
        }).nullable(),
        city: Yup.string().when('partyType', {
          is: (value) => value !== "CB",
          then: Yup.string().required(requiredMessage).matches(regexAlphaNumericSpecChar, aplhaNumericWithSpace).max(30, maxLength30).nullable(),
          otherwise: Yup.string()
        }).nullable(),
        postalCode: Yup.string().when('partyType', {
          is: (value) => value !== "CB",
          then: Yup.string().required(requiredMessage).matches("^[a-zA-Z0-9 ]*$", aplhaNumericWithSpace).max(15, maxLength15).nullable(),
          otherwise: Yup.string()
        }).nullable(),
        countryCode: Yup.string().when('partyType', {
          is: (value) => value !== "CB",
          then: Yup.string().required(requiredMessage).nullable(),
          otherwise: Yup.string()
        }).nullable(),
        stateCode: Yup.string().when('partyType', {
          is: (value) => value !== "CB",
          then: Yup.string().when('countryCode', {
            is: (value) => value === 'US' || value === 'CA',
            then: Yup.string().required(requiredMessage),
            otherwise: Yup.string()
          }),
          otherwise: Yup.string()
        }).nullable(),
        adminName: Yup.string().matches(regexAlphaNumericSpecChar, aplhaNumericWithSpace).max(60, maxLength60).nullable(),
        adminContact: Yup.string().matches("^[a-zA-Z0-9 -]*$", alphaNumericWithSplChar).max(35, maxLength35).nullable(),
        partyDropDown: Yup.string().nullable(),
        faxNo: Yup.string().matches("^[a-zA-Z0-9 -]*$", alphaNumericWithSplChar).max(35, maxLength35).nullable(),
        phoneNo: Yup.string().matches("^[a-zA-Z0-9 -]*$", alphaNumericWithSplChar).max(35, maxLength35).nullable(),
        email: Yup.string().email(emailValid).max(35, maxLength35).nullable(),
      })
    ),

    cargoCommodity: Yup.array(),
    hazardousMaterial: Yup.array(),
  })
}

export default makeValidationSchema
