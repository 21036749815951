import ArrivalCertApiUrls from 'src/apiUrls/ArrivalCertApiUrls'
import FormProperties from './FormProperties'
import { Box, Card, Grid } from '@material-ui/core'
import React from 'react'
import { components, useTranslation } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import ArrivalCertViewPage from './ArrivalCertViewPage'
import ArrivalCertResponsePage from '../response/ArrivalCertResponse'
import ArrivalCertKeys from 'src/constants/locale/key/ArrivalCert'
import Namespace from 'src/constants/locale/Namespace'

const { CngTabs } = components
const {
  form: { CngViewForm }
} = components

function ViewPage({ showNotification }) {
  const { translate } = useTranslation(Namespace.ARRIVAL_CERT)
  const { id } = useParams()
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let inbondArrival = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.TITLE
    )

    let response = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.RESPONSE_TITLE
    )
    return {
      inbondArrival,
      response
    }
  }

  return (
    <Card>
      <Box padding={2}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.inbondArrival,
              tabContent: (
                <ArrivalCertViewPage
                  showNotification={showNotification}
                  id={id}
                />
              )
            },
            {
              tabName: translatedTextsObject.response,
              tabContent: (
                <ArrivalCertResponsePage
                  showNotification={showNotification}
                  id={id}
                />
              )
            }
          ]}
        />
      </Box>
    </Card>
  )
}

export default ViewPage
