import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'
import AciHighwayCargoKeys from 'src/constants/locale/key/AciHighwayCargo'


const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )
  let aplhaNumericWithSpace= translate(
    Namespace.ACI_HIGHWAY_CARGO,
    AciHighwayCargoKeys.Validations.ALPHA_NUMERIC_WITH_SPACE
   )
   let alphaNumericRegx= translate(
    Namespace.ACI_HIGHWAY_CARGO,
    AciHighwayCargoKeys.Validations.ALPHA_NUMERIC_REGX
   )
   let maxLength300= translate(
    Namespace.ACI_HIGHWAY_CARGO,
    AciHighwayCargoKeys.Validations.MAX_LENGTH_300
   )
   let maxLength10= translate(
    Namespace.ACI_HIGHWAY_CARGO,
    AciHighwayCargoKeys.Validations.MAX_LENGTH_10
   )
   let maxLength48= translate(
    Namespace.ACI_HIGHWAY_CARGO,
    AciHighwayCargoKeys.Validations.MAX_LENGTH_48
   )
   let alphaNumericWithSplChar= translate(
    Namespace.ACI_HIGHWAY_CARGO,
    AciHighwayCargoKeys.Validations.ALPHA_NUMERIC_WITH_SPLCHARS
   )
   let maxLength256= translate(
    Namespace.ACI_HIGHWAY_CARGO,
    AciHighwayCargoKeys.Validations.MAX_LENGTH_256
   )
   let maxLength6= translate(
    Namespace.ACI_HIGHWAY_CARGO,
    AciHighwayCargoKeys.Validations.MAX_LENGTH_6
   )
   let maxLength60= translate(
    Namespace.ACI_HIGHWAY_CARGO,
    AciHighwayCargoKeys.Validations.MAX_LENGTH_60
   )
   let numericRegx= translate(
    Namespace.ACI_HIGHWAY_CARGO,
    AciHighwayCargoKeys.Validations.NUMERIC_REGX
   )

  return Yup.object({
    productDesc: Yup.string().required(requiredMessage).matches("^[a-zA-Z0-9 \"&\'()+*,-./:;?=@%#]*$", aplhaNumericWithSpace).max(300,maxLength300).nullable(),
    quantity: Yup.number().required(requiredMessage).nullable(),
    quantityUOM: Yup.string().required(requiredMessage).nullable(),
    weight: Yup.number().required(requiredMessage).nullable(),
    weightUom: Yup.string().required(requiredMessage).nullable(),
    hsCode: Yup.string().matches("^[a-zA-Z0-9]*$", alphaNumericRegx).max(10,maxLength10).nullable(),
    marksNumber: Yup.string().matches("^[a-zA-Z0-9 ]*$", aplhaNumericWithSpace).max(48,maxLength48).nullable(),
    hazardousMaterial: Yup.array(Yup.object({
      undgCode: Yup.string().matches("^[0-9]*$", numericRegx).max(6,maxLength6).required(requiredMessage).nullable(),
      undgDesc: Yup.string().max(300,maxLength300).matches("^[a-zA-Z0-9 \"&\'()+*,-./:;?=@%#]*$", aplhaNumericWithSpace).nullable(),
      adminName: Yup.string().matches("^[a-zA-Z0-9 ]*$", aplhaNumericWithSpace).max(60,maxLength60).nullable(),
      adminContact: Yup.string().matches("^[a-zA-Z0-9 -]*$", alphaNumericWithSplChar).max(256,maxLength256).nullable(),
    })).nullable()
  })
}

export default makeValidationSchema
