
import { Card, CardContent, Grid } from '@material-ui/core'
import React,{useState} from 'react'; 
//import CardHeader from "components/Card/CardHeader.js"; 
import RegularButton from "src/components/CustomButton.js";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import sweetalert from 'sweetalert'
import { 
  components,
  useServices 
} from 'cng-web-lib';
import AciHighwayCargoApiUrls from 'src/apiUrls/AciHighwayCargoApiUrls';
import { useHistory, useLocation } from "react-router-dom";
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'

var cardStyle = {
 height: '41vw'
}
const{
  card: {
     CngSimpleCardHeader,
  },
   CngGridItem,
}=components

function CargoCSVUpload(){ 
  
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileData, seFileData] = useState(null);
  const [errorMessage, setErrorMessage] = useState([]);
  const [errorContent, setErrorContent] = useState(null);

  const {securedSendRequest } = useServices();
  const history = useHistory();

  const topStyle = {
    'marginTop': '20px'
  }

  const fontStyle = {
    'marginTop': '20px',
    'fontSize': '18px',
    'color': 'red',
    'fontWeight': 'bold'
  } 

  const errorMessagefontStyle = {
    'marginTop': '20px',
    'fontSize': '16px',
    'color': 'red',
    'fontWeight': 'bold'
  }



  // On file select (from the pop up) 
  const onFileChange = event => { 
    setErrorContent(null);
    const file = event.target.files[0];
    // Update the state 
    setSelectedFile(file)
    
  }; 
   
  // On file upload (click the upload button) 
  const onFileUpload = () => { 
      setErrorMessage([]);
      let file = selectedFile;
      if(file!=null){
        if(file.name.split(".").pop() != 'csv'){//check if file extension is csv
          sweetalert("Please select CSV file type","" ,"error");
          return false;
        }
        if(file.size > 6291456){
          sweetalert("File size exceed 6MB","" ,"error");
          return false;
        }
      }else{
        sweetalert("Invalid file","" ,"error");
        return false;
      }
     
      var manifestId=0;
      console.log("...history",history.location.state.shouldRerender)
      if(history.location.state!==undefined && history.location.state.shouldRerender!==true)
      {
        manifestId=Number(history.location.state);
      }

    let fileForUserDetails = FileForUserGetUserDetails();
      const formData = new FormData(); 
      formData.append('file', selectedFile);
      formData.append('data', manifestId);
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      formData.append("fileForUserDetails", JSON.stringify(fileForUserDetails))
    } else {
      formData.append("fileForUserDetails", "{}")
    }

    
    // Request made to the backend api 
    // Send formData object 
    var url=AciHighwayCargoApiUrls.UPLOAD;

    function onSuccess(response) {
      if(response.status === 200) {
        var dataObj = response.data
        if(dataObj !== null || dataObj !== undefined){
          var errorMessages = []
          errorMessages = dataObj.errorMessages
          if(errorMessages === null || errorMessages === undefined){
            document.getElementById("csvUpload").value = "";
            setErrorMessage([]);
            sweetalert({
              title: "Cargo CSV Upload",
              text: "File has been uploaded successfully",
              icon: "success",
            })
            if(manifestId>0)
            {
              history.goBack();
            }
          } else {
            setErrorMessage(errorMessages);  
            document.getElementById("csvUpload").value = ""; 
            sweetalert({
              title: "Cargo CSV Upload",
              text: "Validation Errors in the Uploaded File",
              icon: "error"
            })
          }
        }
      }
    }
    
    function onError(error) {
      document.getElementById("csvUpload").value = "";
      sweetalert({
        title: "Cargo CSV Upload",
        text: "Error while Uploading File",
        icon: "error"
      })
    }
    
    function onComplete(){
    }
    
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    }
    securedSendRequest.execute('POST',url, formData, onSuccess, onError, onComplete,config,null);
  }
    
  return ( 
      <Card style={cardStyle}>
        <CngSimpleCardHeader title="Cargo CSV Upload File"/>
        <CardContent>
        <Grid container spacing={3}>
        <Grid item xs={12}>
             <div>
             <span></span>
             <br></br>
             </div>
             <Grid item xs={12}>
              <Grid container>
                <CngGridItem xs={12} sm={4}>
                <RegularButton>
                  <input type="file" id="csvUpload" accept=".csv,text/csv" color="primary" onChange={onFileChange}/>
                </RegularButton>
                </CngGridItem>
                <CngGridItem xs={6} sm={3}>
                <RegularButton  type="submit" color="primary" onClick={onFileUpload}
                  disabled={!selectedFile} >
                  Upload!
                </RegularButton>
                </CngGridItem>
              </Grid> 
              </Grid>
                      <Grid style={topStyle}>
                      {(errorMessage.length > 0) &&( 
                        <span style={fontStyle}>Erroneous File! All records in file have been rejected. Kindly rectify and upload again.</span>
                      )}<br/><br/>
                        <span style={errorMessagefontStyle}>
                          {errorMessage.map((data, index) => (
                            <span key={index}>{data}<br/></span>
                          ))}
                        </span>
                      </Grid>  
                  </Grid>
                </Grid>  
              </CardContent>
        </Card>
    ); 
} 

export default CargoCSVUpload; 