import ArrivalCertApiUrls from 'src/apiUrls/ArrivalCertApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React from 'react'
import { components, useTranslation } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import pathMap from 'src/paths/pathMap'
import Namespace from 'src/constants/locale/Namespace'
import ArrivalCertKeys from 'src/constants/locale/key/ArrivalCert'
import CustomButtonSection from '../../common/CustomButtonSection'

const {
  form: { CngViewForm }
} = components

function ArrivalCertViewPage({ showNotification }) {
  const { id } = useParams()

  const { translate } = useTranslation(Namespace.ARRIVAL_CERT)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let saveButton = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.SAVE_BUTTON
    )
    let submitButton = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.SUBMIT_BUTTON
    )
    let updateButton = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.UPDATE_BUTTON
    )
    return {
      saveButton,
      submitButton,
      updateButton
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngViewForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          showNotification={showNotification}
          renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
            <FormProperties.Fields
              disabled={true}
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
            />
          )}
          formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          fetch={{
            id: id,
            url: ArrivalCertApiUrls.GET
          }}
        />
      </Grid>
    </Grid>
  )
}

export default ArrivalCertViewPage
