import React, { useState, useEffect } from 'react'
import { components, useServices, useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import ArrivalCertKeys from '../../../constants/locale/key/ArrivalCert'
import ArrivalCertApiUrls from '../../../apiUrls/ArrivalCertApiUrls'
import { Box, Grid, Paper } from '@material-ui/core'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import ErrorMessageSection from 'src/views/common/ErrorMessageSection'

const {
  button: { CngButton },
  dropzone: { CngFileUpload },
  CngDialog
} = components

function ArrivalCertUploadDialog(props) {
  const { onClose, open, showNotification } = props

  const [files, setFiles] = useState([])
  const [errorMessages, setErrorMessages] = useState([])
  const [loading, setLoading] = useState(false)
  const { securedSendRequest } = useServices()
  const { translate } = useTranslation(Namespace.ACI_HIGHWAY_ArrivalCert)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let saveButton = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.SAVE_BUTTON
    )

    return { saveButton }
  }

  useEffect(() => {
    setFiles([])
    setErrorMessages([])
  }, [open]);

  const downloadFile = () => {
    const blob = new Blob(["SLNO,ArrivalCert Control Number,Arrival Date  (YYYYMMDDHHMM),Timezone,Inbond Destination Office Code ,Warehouse Sub-loc Code\n,M-Mandatory,Numeric,A-Alpha,N-Numeric,N-Numeric\n,\"M\,1...25 AN\",,,,\n1,MARK26042024XX,202404260923,ED,0809,2009\n2,MARK26042024XXX,202404260923,ED,0809,2009"], { type: "text/csv" })
    const element = document.createElement("a");
    element.href = window.URL.createObjectURL(blob)
    element.download = "ACIHWY_WACM_SampleUpload.csv";
    document.body.appendChild(element);
    element.click();
    element.parentNode.removeChild(element);
  }

  function handleUploadArrivalCert() {
    try {
      if (!files || files.length === 0) {
        showNotification('error', 'No file attached. Please attach a file to upload.');
        return;
      }
      
      setLoading(true)

      let fileForUserDetails = FileForUserGetUserDetails();
      const formData = new FormData()

      formData.append('file', files[0].file)
      if (fileForUserDetails != null && fileForUserDetails != undefined) {
        formData.append("fileForUserDetails", JSON.stringify(fileForUserDetails))
      } else {
        formData.append("fileForUserDetails", "{}")
      }

      securedSendRequest.execute(
        'POST',
        ArrivalCertApiUrls.UPLOAD,
        formData,
        (response) => {
          if (response.status === 200) {
            if (response.data) {
              const errorMessages = response.data.errorMessages

              if (errorMessages) {
                setErrorMessages(errorMessages)
                setFiles([])
              }
            } else {
              showNotification('success', 'File has been uploaded successfully')
              onClose()
              open.tableRef.current.performRefresh()
            }
          }
        },
        () => {
          showNotification('error', 'Something went wrong when uploading ArrivalCert.')
          setFiles([])
        },
        () => setLoading(false)
      )
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <CngDialog
      dialogTitle='WACM Upload'
      dialogContent={
        <Paper variant='outlined'>
          <Box padding={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CngFileUpload
                  accept={['.csv']}
                  files={files}
                  maxFiles={1}
                  maxSize={6291456}
                  onFileSelect={(file) => setFiles(file)}
                  showFormFields={false}
                  moreActions={[
                    {
                      action: 'remove',
                      name: 'Remove',
                      icon: ['fal', 'trash']
                    }
                  ]}
                  onDropRejected={(errorMessages) => showNotification('error', errorMessages)}
                />
              </Grid>

              <ErrorMessageSection errorMessages={errorMessages} />

              <Grid item xs={12}>
                <Grid container justify='flex-end' spacing={2}>
                  <Grid item xs='auto'>
                    <CngButton size='medium' variant='outlined' onClick={downloadFile}>
                      Download template CSV
                    </CngButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      }
      dialogAction={
        <>
          <CngButton color='secondary' disabled={loading} onClick={onClose}>
            Discard
          </CngButton>
          <CngButton
            color='primary'
            disabled={loading}
            onClick={handleUploadArrivalCert}
            shouldShowProgress={loading}
          >
            {translatedTextsObject.saveButton}
          </CngButton>
        </>
      }
      fullWidth
      maxWidth='lg'
      open={open}
      onClose={onClose}
      shouldShowCloseButton
    />
  )
}

export default ArrivalCertUploadDialog
