import { components, useTranslation } from 'cng-web-lib'
import React, { useEffect } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import AciHighwayCargoKeys from 'src/constants/locale/key/AciHighwayCargo'
import { Grid } from '@material-ui/core'
import { useFormContext, useWatch } from "react-hook-form";
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'

const {
  form: {
    field: { CngTextField },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  // _cargoCommodityId: "",
  undgCode: "",
  undgDesc: "",
  adminName: "",
  adminContact: "",
  version: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
}

function Fields({
  disabled,
  showNotification,
  parentIdDropdownItems,
  shouldHideMap,
  index
}) {
  const { setValue, getValues, reset } = useFormContext();
  const { translate } = useTranslation(Namespace.ACI_HIGHWAY_CARGO)
  const translatedTextsObject = makeTranslatedTextsObject()

  useWatch("fileForUserId");
  useWatch("fileForUserLoginId")
  useWatch("fileForUserPartyId");
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  function makeTranslatedTextsObject() {
    let hazardousMaterial = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.HazardousMaterial.TITLE
    )
    let undgCode = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.HazardousMaterial.UNDG_CODE
    )
    let undgDesc = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.HazardousMaterial.UNDG_DESC
    )
    let adminName = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.HazardousMaterial.ADMIN_NAME
    )
    let adminContact = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.HazardousMaterial.ADMIN_CONTACT
    )
    let adminstrativeContact=translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.ADMINSTRATIVE_CONTACT
    )


    return {
      hazardousMaterial,
      undgCode,
      undgDesc,
      adminName,
      adminContact,
      adminstrativeContact
    }
  }

	return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.undgCode}>
        <CngTextField
          required
          name={typeof index === 'number' ? `hazardousMaterial.${index}.undgCode` : 'undgCode'}
          label={translatedTextsObject.undgCode}
          disabled={disabled}
          inputProps={{ maxLength: 6 }}
          onChange={(e) => {
            setValue(
              typeof index === 'number'
                ? `hazardousMaterial.${index}.undgCode`
                : 'undgCode',
              e.target.value.toUpperCase(),
              { shouldValidate: true }
            )
          }}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.adminName}>
        <CngTextField
          name={typeof index === 'number' ? `hazardousMaterial.${index}.adminName` : 'adminName'}
          label={translatedTextsObject.adminName}
          disabled={disabled}
          inputProps={{ maxLength: 60 }}
          onChange={(e) => {
            setValue(
              typeof index === 'number'
                ? `hazardousMaterial.${index}.adminName`
                : 'adminName',
              e.target.value.toUpperCase(),
              { shouldValidate: true }
            )
          }}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={4} shouldHide={shouldHideMap?.adminContact}>
        <CngTextField
          name={typeof index === 'number' ? `hazardousMaterial.${index}.adminContact` : 'adminContact'}
          label={translatedTextsObject.adminContact}
          disabled={disabled}
          inputProps={{ maxLength: 35 }}
          onChange={(e) => {
            setValue(
              typeof index === 'number'
                ? `hazardousMaterial.${index}.adminContact`
                : 'adminContact',
              e.target.value.toUpperCase(),
              { shouldValidate: true }
            )
          }}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} shouldHide={shouldHideMap?.undgDesc}>
        <CngTextField
          name={typeof index === 'number' ? `hazardousMaterial.${index}.undgDesc` : 'undgDesc'}
          label={translatedTextsObject.undgDesc}
          disabled={disabled}
          inputProps={{ maxLength: 300 }}
          onChange={(e) => {
            setValue(
              typeof index === 'number'
                ? `hazardousMaterial.${index}.undgDesc`
                : 'undgDesc',
              e.target.value.toUpperCase(),
              { shouldValidate: true }
            )
          }}
          size='small'
        />
      </CngGridItem>
    </Grid>
	)
}

const HazardousMaterialFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default HazardousMaterialFormProperties
