import { Box, Grid, Typography } from '@material-ui/core'
import { constants, useServices, useTranslation } from 'cng-web-lib'
import moment from 'moment'
import ValidationErrorLogApiUrls from 'src/apiUrls/ValidationErrorLogApiUrls'
import React, { useContext, useRef, useState } from 'react'
import ValidationErrorLogKeys from 'src/constants/locale/key/ValidationErrorLog'
import Namespace from 'src/constants/locale/Namespace'
import Table from '../../../components/aciacehighway/Table'
import { LookupsContext } from '../../../contexts/LookupsContext'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'
import _ from 'lodash'

const {
  filter: { BETWEEN, LIKE }
} = constants

function TablePage(props) {
  const { showNotification } = props

  const { deleteRecord } = useServices()
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    reports: []
  })
  const { getLookupValue } = useContext(LookupsContext)

  const { translate } = useTranslation(Namespace.VALIDATION_ERROR_LOG)
  const translatedTextsObject = makeTranslatedTextsObject()
  const tableRef = useRef()

  function makeTranslatedTextsObject() {
    let procTime = translate(
      Namespace.VALIDATION_ERROR_LOG,
      ValidationErrorLogKeys.PROC_TIME
    )
    let flightNo = translate(
      Namespace.VALIDATION_ERROR_LOG,
      ValidationErrorLogKeys.FLIGHT_NO
    )
    let docType = translate(
      Namespace.VALIDATION_ERROR_LOG,
      ValidationErrorLogKeys.DOC_TYPE
    )
    let transportId = translate(
      Namespace.VALIDATION_ERROR_LOG,
      ValidationErrorLogKeys.TRANSPORT_ID
    )
    let parn = translate(
      Namespace.VALIDATION_ERROR_LOG,
      ValidationErrorLogKeys.PARN
    )
    let errorType = translate(
      Namespace.VALIDATION_ERROR_LOG,
      ValidationErrorLogKeys.ERROR_TYPE
    )

    return {
      procTime,
      flightNo,
      docType,
      transportId,
      parn,
      errorType
    }
  }

  const columns = [
    {
      field: 'procTime',
      sortKey: 'procTime',
      title: translatedTextsObject.procTime,
      render: (rowData) =>
        rowData.procTime &&
        moment(rowData.procTime).format('D MMM YYYY - HH:mm')
    },
    {
      field: 'flightNo',
      sortKey: 'flightNo',
      title: translatedTextsObject.flightNo
    },
    {
      field: 'docType',
      sortKey: 'docType',
      title: [
        translatedTextsObject.docType,
        translatedTextsObject.transportId
      ].join(' / '),
      render: (rowData) => (
        <>
          <Typography component='div' variant='inherit'>
            {rowData.docType}
          </Typography>
          <Typography variant='caption' color='textSecondary'>
            {rowData.transportId}
          </Typography>
        </>
      )
    },
    {
      field: 'parn',
      sortKey: 'parn',
      title: translatedTextsObject.parn
    },
    {
      field: 'errorType',
      sortKey: 'errorType',
      title: translatedTextsObject.errorType,
      render: (rowData) => getLookupValue('errorType', rowData.errorType)
    },
    {
      field: 'aciAirFedexValidationDetailDTO',
      sortKey: 'aciAirFedexValidationDetailDTO',
      title: 'Error Field | Error Desc',
      render: (rowData) => {
        const errorCodes = rowData?.aciAirFedexValidationDetailDTO || []

        return (
          <Grid container spacing={2}>
            {errorCodes.map((error, index) => {
              const { errorFiled, errorDesc } = error

              return (
                <Grid key={index} item xs={12}>
                  <Typography
                    component='div'
                    variant='body2'
                    style={{ fontWeight: 600 }}
                  >
                    {errorDesc}
                  </Typography>
                  {errorFiled && (
                    <Box mt={1}>
                      <Typography variant='caption' color='textSecondary'>
                        {errorFiled}
                      </Typography>
                    </Box>
                  )}
                </Grid>
              )
            })}
          </Grid>
        )
      }
    }
  ]

  const filters = [
    {
      label: translatedTextsObject.procTime,
      type: 'daterange',
      name: 'procTime',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.flightNo,
      type: 'textfield',
      name: 'flightNo',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.docType,
      type: 'textfield',
      name: 'docType',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.parn,
      type: 'textfield',
      name: 'parn',
      operatorType: LIKE
    }
  ]

  function handleDeleteLog() {
    if (_.isEmpty(confirmDialog.log)) return

    deleteRecord.execute(
      ValidationErrorLogApiUrls.DELETE,
      confirmDialog.log,
      () => {
        showNotification('success', 'Delete succeeded')

        if (tableRef.current?.performRefresh) {
          tableRef.current.performRefresh()
        }
      },
      (error) => console.error(error),
      () => setConfirmDialog({ open: false, log: null })
    )
  }

  return (
    <>
      <Table
        columns={columns}
        compact
        customRowActions={[
          {
            icon: ['fal', 'arrow-alt-right'],
            onClick: (rowData) =>
              setConfirmDialog({ open: true, log: rowData }),
            tooltip: () => 'Delete'
          }
        ]}
        fetch={{ url: ValidationErrorLogApiUrls.GET }}
        filters={filters}
        showNotification={showNotification}
        sortConfig={{
          type: 'column',
          defaultField: 'createdDate',
          defaultDirection: 'DESC'
        }}
        persistSettings
        tableRef={tableRef}
      />
      <AlertDialog
        cancelLabel='No, take me back'
        confirmLabel='Yes, delete'
        open={confirmDialog.open}
        onClose={() => setConfirmDialog({ open: false, log: null })}
        onCancel={() => setConfirmDialog({ open: false, log: null })}
        onConfirm={handleDeleteLog}
        title='Delete'
      >
        Items that you delete can't be restored. Are you sure about this?
      </AlertDialog>
    </>
  )
}

export default TablePage
