import ArrivalCertApiUrls from 'src/apiUrls/ArrivalCertApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React, { useRef, useEffect } from 'react'
import { components, DataFlattener, useServices, useTranslation } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import pathMap from 'src/paths/pathMap'
import Namespace from 'src/constants/locale/Namespace'
import ArrivalCertKeys from 'src/constants/locale/key/ArrivalCert'
import withFormState from '../../../components/aciacehighway/hocs/withFormState'
import { useFormContext } from 'react-hook-form'

const {
  button: { CngButton },
  form: { CngEditForm }
} = components

function ArrivalCertEditPage(props) {
  const { history, onSetLoading, showNotification } = props
  const { id } = useParams()

  const { translate } = useTranslation(Namespace.ARRIVAL_CERT)
  const translatedTextsObject = makeTranslatedTextsObject()
  const onSuccessCallback = useRef(() => { })

  function makeTranslatedTextsObject() {
    let saveButton = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.SAVE_BUTTON
    )
    let submitButton = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.SUBMIT_BUTTON
    )
    let updateButton = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.UPDATE_BUTTON
    )
    return {
      saveButton,
      submitButton,
      updateButton
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngEditForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          showNotification={showNotification}
          renderBodySection={(labelMap, shouldHideMap, shouldDisableMap) => (
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
            />
          )}
          formikProps={FormProperties.formikProps}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          fetch={{
            url: ArrivalCertApiUrls.GET
          }}
          update={{
            url: ArrivalCertApiUrls.PUT
          }}
          id={id}
          renderButtonSection={(_, loading) => (
            <ButtonSection
              history={history}
              loading={loading}
              onSetLoading={onSetLoading}
              updateButtonLabel={translatedTextsObject.updateButton}
              showNotification={showNotification}
              submitButtonLabel={translatedTextsObject.submitButton}
              onSave={() => {
                onSuccessCallback.current = (e) => {
                  onSetLoading(false)
                }
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}

export default withFormState(ArrivalCertEditPage)

function ButtonSection(props) {
  const {
    history,
    loading,
    onSetLoading,
    updateButtonLabel,
    showNotification,
    submitButtonLabel,
    onSave
  } = props

  const { securedSendRequest } = useServices()
  const { formState: { isSubmitting }, getValues } = useFormContext()

  useEffect(() => {
    onSetLoading(loading)
  }, [loading])

  function handleSubmit() {
    onSetLoading(true)
    let values = { ...getValues() }

    securedSendRequest.execute(
      'POST',
      ArrivalCertApiUrls.SUBMIT,
      DataFlattener.unflatten(values),
      (response) => {
        var data = response.data
        var errorMessages = data.errorMessages
        if (response.status == 200 && data != null && errorMessages == null) {
          showNotification('success', 'Submitted successfully')
          history.push(pathMap.ACI_HIGHWAY_INBOND_ARRIVAL_LIST_VIEW)
        } else {
          showNotification('error', errorMessages)
        }
      },
      () => onSetLoading(false)
    )
  }

  return (
    <Grid container justify='space-between' spacing={3}>
      <Grid item xs='auto'>
        <CngButton
          color='secondary'
          disabled={isSubmitting || loading}
          onClick={() => history.push(pathMap.ACI_HIGHWAY_INBOND_ARRIVAL_LIST_VIEW)}
          size='medium'
        >
          Discard
        </CngButton>
      </Grid>
      <Grid item xs='auto'>
        <Grid container spacing={2}>
          <Grid item xs='auto'>
            <CngButton
              color='primary'
              type='submit'
              size='medium'
              disabled={isSubmitting || loading}
              shouldShowProgress={loading}
              onClick={onSave}
            >
              {updateButtonLabel}
            </CngButton>
          </Grid>
          <Grid item xs='auto'>
            <CngButton
              color='primary'
              disabled={isSubmitting || loading}
              onClick={handleSubmit}
              size='medium'
            >
              {submitButtonLabel}
            </CngButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
