import React, { useState } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import AciHighwayCargoKeys from 'src/constants/locale/key/AciHighwayCargo'
import TinyChip from 'src/components/aciacehighway/TinyChip'
import {
  Box,
  Grid,
  InputAdornment,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'

const {
  button: { CngButton, CngIconButton }
} = components

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableHead-root': {
      backgroundColor: theme.palette.background.sectionOddBg,
      '& .MuiTableCell-head': {
        borderBottom: 0,
        fontSize: 12,
        fontWeight: 700,
        lineHeight: 1.2,
        textTransform: 'uppercase'
      }
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        '&:last-child': {
          '& .MuiTableCell-root': {
            borderBottom: 0
          }
        }
      }
    },
    '& .MuiTableCell-root': {
      padding: '8px 16px'
    }
  }
}))

function CargoCommodityTable(props) {
  const {
    cargoCommodity,
    onAddCargoCommodity,
    onEditCargoCommodity,
    onDeleteCargoCommodity,
    onViewCargoCommodity
  } = props

  const [searchTerm, setSearchTerm] = useState('')
  const { translate } = useTranslation(Namespace.ACI_HIGHWAY_CARGO)
  const translatedTextsObject = makeTranslatedTextsObject()
  const theme = useTheme()
  const classes = useStyles()

  function makeTranslatedTextsObject() {
    let productDesc = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CargoCommodity.PRODUCT_DESC
    )
    let quantity = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CargoCommodity.QUANTITY
    )
    let quantityUOM = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CargoCommodity.QUANTITY_U_O_M
    )
    let weight = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CargoCommodity.WEIGHT
    )
    let weightUom = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CargoCommodity.WEIGHT_UOM
    )
    let hsCode = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CargoCommodity.HS_CODE
    )
    let marksNumber = translate(
      Namespace.ACI_HIGHWAY_CARGO,
      AciHighwayCargoKeys.CargoCommodity.MARKS_NUMBER
    )

    return {
      productDesc,
      quantity,
      quantityUOM,
      weight,
      weightUom,
      hsCode,
      marksNumber
    }
  }

  const filtered =
    searchTerm !== ''
      ? cargoCommodity.filter(
          (cargo) =>
            cargo.productDesc.includes(searchTerm) ||
            cargo.hsCode.includes(searchTerm)
        )
      : cargoCommodity

  return (
    <>
      <Box marginBottom={1}>
        <Grid container spacing={2}>
          <Grid item xs>
            {cargoCommodity.length > 0 && (
              <TextField
                fullWidth
                onChange={(event) => setSearchTerm(event.target.value)}
                placeholder='Search in your entries'
                size='small'
                variant='outlined'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <FontAwesomeIcon icon={['fal', 'search']} />
                    </InputAdornment>
                  )
                }}
              />
            )}
          </Grid>
          {onAddCargoCommodity && (
            <Grid item xs='auto'>
              <CngButton
                color='secondary'
                onClick={onAddCargoCommodity}
                size='medium'
                startIcon={<FontAwesomeIcon icon={['fal', 'plus-circle']} />}
              >
                Add commodity
              </CngButton>
            </Grid>
          )}
        </Grid>
      </Box>
      <TableContainer>
        <Table className={clsx(classes.table, 'new-ui-table-component__table')}>
          <TableHead>
            <TableRow>
              <TableCell>
                {`${translatedTextsObject.hsCode} / ${translatedTextsObject.productDesc}`}
              </TableCell>
              <TableCell>
                {`${translatedTextsObject.quantity} / ${translatedTextsObject.quantityUOM}`}
              </TableCell>
              <TableCell>
                {`${translatedTextsObject.weight} / ${translatedTextsObject.weightUom}`}
              </TableCell>
              {(onEditCargoCommodity || onDeleteCargoCommodity) && (
                <TableCell align='right'>Action</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {filtered.length > 0 ? (
              filtered.map((cargo) => (
                <TableRow
                  key={Math.random()}
                  hover
                  onClick={() => {
                    if (onViewCargoCommodity) {
                      onViewCargoCommodity(cargo)
                    }
                  }}
                >
                  <TableCell>
                    {[cargo.hsCode || '-', cargo.productDesc || '-'].join(' / ')}
                  </TableCell>
                  <TableCell>
                    <Typography component='div' variant='inherit'>
                      {cargo.quantity}
                      <TinyChip label={cargo.quantityUOM} variant='outlined' />
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography component='div' variant='inherit'>
                      {cargo.weight}
                      <TinyChip label={cargo.weightUom} variant='outlined' />
                    </Typography>
                  </TableCell>
                  {(onEditCargoCommodity || onDeleteCargoCommodity) && (
                    <TableCell align='right'>
                      <Grid container spacing={1} justify='flex-end'>
                        {onEditCargoCommodity && (
                          <Grid item xs='auto'>
                            <CngIconButton
                              type='outlined'
                              icon={['fal', 'pen']}
                              onClick={(event) => {
                                event.stopPropagation()
                                onEditCargoCommodity(cargo)
                              }}
                              size='small'
                            />
                          </Grid>
                        )}
                        {onDeleteCargoCommodity && (
                          <Grid item xs='auto'>
                            <CngIconButton
                              type='outlined'
                              icon={['fal', 'trash']}
                              onClick={(event) => {
                                event.stopPropagation()
                                onDeleteCargoCommodity(cargo)
                              }}
                              size='small'
                              style={{ color: theme.palette.error.main }}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  align='center'
                  colSpan={3 + (onEditCargoCommodity || onDeleteCargoCommodity ? 1 : 0)}
                >
                  <Typography variant='body2' color='textSecondary'>
                    No records to display
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default CargoCommodityTable
